import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { getLevels, getUnits } from "../utils/DbHelpers";
import {
  addLevels,
  addUnits,
  addLessons,
  addQuestions,
} from "../utils/ApiHelpers";
import PopUpPreview from "./PopUpPreview";
import AddQuestionPopUpPreview from "./AddQuestionPopUpPreview";

const Background = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  margin: auto;
`;
const ModalWrapper = styled.div`
  width: 800px;
  max-height: 700px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 30;
  border-radius: 10px;
  overflow: scroll;
  padding-bottom: 25px;
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 30;
`;

const AddQuestionPopUp = ({
  showModal,
  setShowModal,
  keyForPopUp,
  props,
  question,
  responses,
}) => {
  const modalRef = useRef();
  const [buttonDisable, setbuttonDisable] = useState(true);
  const [stateindex, setstateindex] = useState(0);
  const [preview, setPreview] = useState(false);
  const [chooseQuestionType, setChooseQuestionType] = useState(null);
  const [questionType, setQuestionType] = useState(null);
  const [responseType, setResponseType] = useState(null);
  // State for level
  const [levelValue, setLevelValue] = useState();
  // State for Unit
  const [unitValue, setUnitValue] = useState();
  const [levelIdForUnit, setLevelIdForUnit] = useState();
  const [levels, setLevels] = useState();
  // State for lesson
  const [unitIdForLesson, setUnitIdForLesson] = useState(null);
  const [units, setUnits] = useState();
  const [lessonTitle, setLessonTitle] = useState(null);
  const [lessonSortOrder, setLessonSortOrder] = useState(null);

  // Load images
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  // Load audios
  const [audio1, setAudio1] = useState(null);
  const [audio2, setAudio2] = useState(null);
  // Set Text Values
  const [text1, setText1] = useState(null);
  const [text2, setText2] = useState(null);
  const [audioText1, setAudioText1] = useState(null);
  const [audioText2, setAudioText2] = useState(null);
  const [qesText, setQesText] = useState(null);

  // State for Correct and InCorrect Response
  const [res1IsCorrect, setRes1IsCorrect] = useState(0);
  const [res2IsCorrect, setRes2IsCorrect] = useState(0);

  const formInputHandler = (e) => {
    if (keyForPopUp === "Levels") {
      setLevelValue(e.target.value);
      setbuttonDisable(e.target.value.length === 0 ? true : false);
    }

    if (keyForPopUp === "Units") {
      if (e.target.name === "unit_title") {
        setUnitValue(e.target.value);
        setbuttonDisable(
          e.target.value.length && levelIdForUnit ? false : true
        );
      } else if (e.target.name === "level_id") {
        setLevelIdForUnit(e.target.value);
        setbuttonDisable(e.target.value.length && unitValue ? false : true);
      }
    }

    if (keyForPopUp === "Lessons") {
      if (e.target.name === "unit_id") {
        setUnitIdForLesson(e.target.value);
        setbuttonDisable(
          e.target.value.length && lessonTitle && lessonSortOrder ? false : true
        );
      } else if (e.target.name === "lesson_title") {
        setLessonTitle(e.target.value);
        setbuttonDisable(
          e.target.value.length && unitIdForLesson && lessonSortOrder
            ? false
            : true
        );
      } else if (e.target.name === "lesson_sort_order") {
        setLessonSortOrder(e.target.value);
        setbuttonDisable(
          e.target.value.length && unitIdForLesson && lessonTitle ? false : true
        );
      }
    }

    if (keyForPopUp === "Questions") {
      if (questionType === "IMAGE-AUDIO") {
        if (e.target.name === "qus_text") {
          setQesText(e.target.value);
          setbuttonDisable(
            e.target.value.length &&
              res2IsCorrect &&
              res1IsCorrect &&
              audioText1 &&
              audioText2 &&
              img1 != null &&
              audio1 != null &&
              audio2 != null
              ? false
              : true
          );
        } else if (e.target.name === "img") {
          imageHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              audioText1 &&
              res2IsCorrect &&
              res1IsCorrect &&
              audioText2 &&
              qesText &&
              audio1 != null &&
              audio2 != null
              ? false
              : true
          );
        } else if (e.target.name === "audio1") {
          audioHandler(e, stateindex);
          setbuttonDisable(
            img1 != null &&
              qesText &&
              res2IsCorrect &&
              res1IsCorrect &&
              audioText1 &&
              audioText2 &&
              e.target.files[0] &&
              audio2 != null
              ? false
              : true
          );
        } else if (e.target.name === "audio2") {
          audioHandler(e, stateindex + 1);
          setbuttonDisable(
            img1 != null &&
              qesText &&
              res2IsCorrect &&
              res1IsCorrect &&
              audioText1 &&
              audioText2 &&
              audio1 != null &&
              e.target.files[0]
              ? false
              : true
          );
        } else if (e.target.name === "audio_text1") {
          audioTextHandler(e, stateindex);
          setbuttonDisable(
            img1 != null &&
              qesText &&
              res2IsCorrect &&
              res1IsCorrect &&
              audioText2 &&
              audio1 != null &&
              audio2 != null &&
              e.target.value
              ? false
              : true
          );
        } else if (e.target.name === "audio_text2") {
          audioTextHandler(e, stateindex + 1);
          setbuttonDisable(
            img1 != null &&
              qesText &&
              res2IsCorrect &&
              res1IsCorrect &&
              audioText1 &&
              audio1 != null &&
              audio2 != null &&
              e.target.value
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            img1 != null &&
              qesText &&
              res2IsCorrect &&
              audioText1 &&
              audio1 != null &&
              audio2 != null &&
              e.target.value
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            img1 != null &&
              qesText &&
              res1IsCorrect &&
              audioText1 &&
              audio1 != null &&
              audio2 != null &&
              e.target.value
              ? false
              : true
          );
        }
      }

      if (questionType === "AUDIO-IMAGE") {
        if (e.target.name === "audio") {
          audioHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              img1 != null &&
              img2 != null
              ? false
              : true
          );
        } else if (e.target.name === "img1") {
          imageHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              img2 != null
              ? false
              : true
          );
        } else if (e.target.name === "img2") {
          imageHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              img1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            e.target.value && res2IsCorrect && audio1 != null && img1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value && res1IsCorrect && audio1 != null && img1 != null
              ? false
              : true
          );
        }
      }

      if (questionType === "AUDIO-IMAGE-TEXT") {
        if (e.target.name === "audio") {
          console.log(e.target.name);
          audioHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              img1 != null &&
              text1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "img1") {
          console.log(e.target.name);
          imageHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              text1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text1") {
          console.log(e.target.name);
          textHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              img1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text2") {
          console.log(e.target.name);
          textHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              img1 != null &&
              text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res2IsCorrect &&
              audio1 != null &&
              img1 != null &&
              text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              audio1 != null &&
              img1 != null &&
              text1 != null
              ? false
              : true
          );
        }
      }

      if (questionType === "TEXT-AUDIO") {
        if (e.target.name === "qus_text") {
          setQesText(e.target.value);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              audioText1 &&
              audioText2 &&
              audio2 != null
              ? false
              : true
          );
        } else if (e.target.name === "audio1") {
          audioHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              audioText1 &&
              audioText2 &&
              audio2 != null
              ? false
              : true
          );
        } else if (e.target.name === "audio2") {
          audioHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              audioText1 &&
              audioText2 &&
              audio1 != null
              ? false
              : true
          );
        } else if (e.target.name === "audio_text1") {
          audioTextHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              audio1 != null &&
              audio1 != null &&
              audioText2
              ? false
              : true
          );
        } else if (e.target.name === "audio_text2") {
          audioTextHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              audio1 != null &&
              audio1 != null &&
              audioText1
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res2IsCorrect &&
              qesText &&
              audio1 != null &&
              audio1 != null &&
              audioText1
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              qesText &&
              audio1 != null &&
              audio1 != null &&
              audioText1
              ? false
              : true
          );
        }
      }

      if (questionType === "TEXT-TEXT") {
        if (e.target.name === "qus_text") {
          setQesText(e.target.value);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              text1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text1") {
          textHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text2") {
          textHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            e.target.value && res2IsCorrect && qesText && text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value && res1IsCorrect && qesText && text1 != null
              ? false
              : true
          );
        }
      }

      if (questionType === "AUDIO-TEXT") {
        if (e.target.name === "audio") {
          audioHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              text1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text1") {
          textHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text2") {
          textHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              audio1 != null &&
              text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            e.target.value && res2IsCorrect && audio1 != null && text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value && res1IsCorrect && audio1 != null && text1 != null
              ? false
              : true
          );
        }
      }

      if (questionType === "IMAGE-TEXT") {
        if (e.target.name === "qus_text") {
          setQesText(e.target.value);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              img1 &&
              text1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "img") {
          imageHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              text1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text1") {
          textHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              img1 != null &&
              text2 != null
              ? false
              : true
          );
        } else if (e.target.name === "text2") {
          textHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText &&
              img1 != null &&
              text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            e.target.value &&
              res2IsCorrect &&
              qesText &&
              img1 != null &&
              text1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              qesText &&
              img1 != null &&
              text1 != null
              ? false
              : true
          );
        }
      }

      if (questionType === "TEXT-IMAGES") {
        if (e.target.name === "qus_text") {
          setQesText(e.target.value);
          setbuttonDisable(
            e.target.value &&
              res1IsCorrect &&
              res2IsCorrect &&
              img1 != null &&
              img2 != null
              ? false
              : true
          );
        } else if (e.target.name === "img1") {
          imageHandler(e, stateindex);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText != null &&
              img2 != null
              ? false
              : true
          );
        } else if (e.target.name === "img2") {
          imageHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.files[0] &&
              res1IsCorrect &&
              res2IsCorrect &&
              qesText != null &&
              img1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res1_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex);
          setbuttonDisable(
            e.target.value && res2IsCorrect && qesText != null && img1 != null
              ? false
              : true
          );
        } else if (e.target.name === "res2_is_correct") {
          responseCorrectInCorrectHandler(e, stateindex + 1);
          setbuttonDisable(
            e.target.value && res1IsCorrect && qesText != null && img1 != null
              ? false
              : true
          );
        }
      }
    }
  };

  const handalSubmitBtn = () => {
    if (keyForPopUp === "Levels") {
      console.log(levelValue);
    }
  };

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const setValuesToNull = () => {
    setbuttonDisable(true);
    setLevelValue(null);
    setShowModal(false);
    setUnitValue(null);
    setLevelIdForUnit(null);
    setLevels(null);
    setUnitIdForLesson(null);
    setUnits(null);
    setLessonTitle(null);
    setLessonSortOrder(null);
    setPreview(false);
    setQesText(null);
    setAudio1(null);
    setAudio2(null);
    setImg1(null);
    setImg2(null);
    setAudioText1(null);
    setAudioText2(null);
    setText1(null);
    setText2(null);
    setChooseQuestionType(null);
    setRes2IsCorrect(0);
    setRes1IsCorrect(0);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
      setValuesToNull();
    }
  };

  const buttonClose = () => {
    setShowModal(false);
    setValuesToNull();
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
        setValuesToNull();
      }
    },
    [setShowModal, showModal]
  );

  useEffect(async () => {
    // console.log('Check the responses...', responses.length);
    if (keyForPopUp === "Units") {
      const levelsData = await getLevels();
      setLevels(levelsData);
    } else if (keyForPopUp === "Lessons") {
      const parm_level_id = parseInt(props.match.params.level_id);
      const unitsData = await getUnits(parm_level_id);
      setUnits(unitsData);
    }

    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress, keyForPopUp, questionType, responseType, chooseQuestionType]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (keyForPopUp === "Levels") {
      const data = new FormData();
      data.append("level_title", levelValue);
      addLevels(data);
    }

    if (keyForPopUp === "Units") {
      const data = new FormData();
      data.append("level_id", levelIdForUnit);
      data.append("unit_title", unitValue);
      addUnits(data);
    }

    if (keyForPopUp === "Lessons") {
      const data = new FormData();
      data.append("unit_id", unitIdForLesson);
      data.append("lesson_title", lessonTitle);
      data.append("lesson_sort_order", lessonSortOrder);
      addLessons(data);
    }

    if (keyForPopUp === "Questions") {
      if (questionType === "IMAGE-AUDIO") {
        const data = new FormData();
        data.append("question_type", questionType);
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("question_text", qesText);
        data.append("img1", img1);
        data.append("audio1", audio1);
        data.append("audio_text1", audioText1);
        data.append("audio2", audio2);
        data.append("audio_text2", audioText2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }

      if (questionType === "AUDIO-IMAGE") {
        const data = new FormData();
        data.append("question_type", questionType);
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("audio1", audio1);
        data.append("img1", img1);
        data.append("img2", img2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }

      if (questionType === "AUDIO-IMAGE-TEXT") {
        const data = new FormData();
        data.append("question_type", "AUDIO-IMAGE-TEXT");
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("audio1", audio1);
        data.append("img1", img1);
        data.append("text1", text1);
        data.append("text2", text2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }

      if (questionType === "TEXT-AUDIO") {
        const data = new FormData();
        data.append("question_type", questionType);
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("qes_text", qesText);
        data.append("audio1", audio1);
        data.append("audio2", audio2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }

      if (questionType === "TEXT-TEXT") {
        const data = new FormData();
        data.append("question_type", questionType);
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("qes_text", qesText);
        data.append("text1", text1);
        data.append("text2", text2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }

      if (questionType === "AUDIO-TEXT") {
        const data = new FormData();
        data.append("question_type", questionType);
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("audio1", audio1);
        data.append("text1", text1);
        data.append("text2", text2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }

      if (questionType === "IMAGE-TEXT") {
        const data = new FormData();
        data.append("question_type", questionType);
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("qes_text", qesText);
        data.append("img1", img1);
        data.append("text1", text1);
        data.append("text2", text2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }

      if (questionType === "TEXT-IMAGES") {
        const data = new FormData();
        data.append("question_type", questionType);
        data.append("level_id", props.match.params.level_id);
        data.append("unit_id", props.match.params.unit_id);
        data.append("lesson_id", props.match.params.lesson_id);

        data.append("qes_text", qesText);
        data.append("img1", img1);
        data.append("img2", img2);
        data.append("res1_isCorrect", res1IsCorrect);
        data.append("res2_isCorrect", res2IsCorrect);
        addQuestions(data);
      }
    }

    setValuesToNull();
  };

  const imageHandler = (e, index) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (index === 0) {
          setImg1(reader.result);
        } else {
          setImg2(reader.result);
        }
      }
    };
    // setbuttonDisable(false)
    reader.readAsDataURL(e.target.files[0]);
  };

  const audioHandler = (e, index) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (index === 0) {
          setAudio1(reader.result);
        } else {
          setAudio2(reader.result);
        }
      }
    };
    // setbuttonDisable(false)
    reader.readAsDataURL(e.target.files[0]);
  };

  const textHandler = (e, index) => {
    if (index === 0) {
      setText1(e.target.value);
    } else {
      setText2(e.target.value);
    }
  };

  const responseCorrectInCorrectHandler = (e, index) => {
    if (index === 0) {
      setRes1IsCorrect(e.target.value);
    } else {
      setRes2IsCorrect(e.target.value);
    }
  };

  const audioTextHandler = (e, index) => {
    if (index === 0) {
      setAudioText1(e.target.value);
    } else {
      setAudioText2(e.target.value);
    }
  };

  const ChooseOptions = (e) => {
    if (e.target.value != null) {
      setQuestionType(e.target.value);
      if (e.target.value === "IMAGE-AUDIO") {
        setResponseType("AUDIO");
      }
      if (e.target.value === "AUDIO-IMAGE") {
        setResponseType("IMAGE");
      }
      if (e.target.value === "TEXT-AUDIO") {
        setResponseType("AUDIO");
      }
      if (e.target.value === "TEXT-TEXT") {
        setResponseType("TEXT");
      }
      if (e.target.value === "AUDIO-TEXT") {
        setResponseType("TEXT");
      }
      if (e.target.value === "IMAGE-TEXT") {
        setResponseType("TEXT");
      }
      if (e.target.value === "TEXT-IMAGES") {
        setResponseType("IMAGES");
      }
    }
    setChooseQuestionType(e.target.value);
  };

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              {keyForPopUp === "Levels" && (
                <>
                  <h3 className="form-title">Add Level</h3>
                  <form onSubmit={formSubmit} className="form">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Level Title:</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Level Title"
                          onChange={(e) => formInputHandler(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <input
                          type="submit"
                          disabled={buttonDisable}
                          className="btn btn-sm btn-primary fr-btn"
                        />
                      </div>
                    </div>
                  </form>
                </>
              )}

              {keyForPopUp === "Units" && (
                <>
                  <h3 className="form-title">Add Unit</h3>
                  <form onSubmit={formSubmit} className="form">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Select Level:</label>
                        <select
                          className="form-control"
                          name="level_id"
                          onChange={(e) => formInputHandler(e)}
                        >
                          <option value="">Choose One...</option>
                          {levels.map((level, index) => (
                            <option key={index} value={level.id}>
                              {level.title}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Unit Title:</label>
                        <input
                          type="text"
                          name="unit_title"
                          className="form-control"
                          placeholder="Unit Title"
                          onChange={(e) => formInputHandler(e)}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <input
                          type="submit"
                          disabled={buttonDisable}
                          className="btn btn-sm btn-primary fr-btn"
                        />
                      </div>
                    </div>
                  </form>
                </>
              )}

              {keyForPopUp === "Lessons" && (
                <>
                  <h3 className="form-title">Add Lesson</h3>
                  <form onSubmit={formSubmit} className="form">
                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Select Unit:</label>
                        <select
                          className="form-control"
                          name="unit_id"
                          onChange={(e) => formInputHandler(e)}
                        >
                          <option value="">Choose One...</option>
                          {units.map((unit, index) => (
                            <option value={unit.id}>{unit.title}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-group">
                        <label>Lesson Title:</label>
                        <input
                          type="text"
                          name="lesson_title"
                          className="form-control"
                          placeholder="Lesson Title"
                          onChange={(e) => formInputHandler(e)}
                        />
                      </div>
                      <div className="col-md-6 form-group">
                        <label>Sort Order:</label>
                        <input
                          type="number"
                          name="lesson_sort_order"
                          className="form-control"
                          placeholder="Sort Order e.g: 1 or 2"
                          onChange={(e) => formInputHandler(e)}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <input
                          type="submit"
                          disabled={buttonDisable}
                          className="btn btn-sm btn-primary fr-btn"
                        />
                      </div>
                    </div>
                  </form>
                </>
              )}

              {keyForPopUp === "Questions" && (
                <>
                  <h3 className="form-title">Add Question</h3>
                  <form onSubmit={formSubmit} className="form">
                    <div className="row">
                      <div className="col-md-4 form-group">
                        <label>Level ID:</label>
                        <input
                          type="number"
                          value={props.match.params.level_id}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label>Unit ID:</label>
                        <input
                          type="number"
                          value={props.match.params.unit_id}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-4 form-group">
                        <label>Lesson ID:</label>
                        <input
                          type="number"
                          value={props.match.params.lesson_id}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 form-group">
                        <label>Choose Question Type:</label>
                        <select
                          className="form-control mb-4"
                          onChange={(e) => ChooseOptions(e)}
                        >
                          <option value="">Choose One...</option>
                          <option value="IMAGE-AUDIO">IMAGE-AUDIO</option>
                          <option value="AUDIO-IMAGE">AUDIO-IMAGE</option>
                          <option value="TEXT-AUDIO">TEXT-AUDIO</option>
                          <option value="TEXT-TEXT">TEXT-TEXT</option>
                          <option value="AUDIO-TEXT">AUDIO-TEXT</option>
                          <option value="IMAGE-TEXT">IMAGE-TEXT</option>
                          <option value="TEXT-IMAGES">TEXT-IMAGES</option>
                          <option value="AUDIO-IMAGE-TEXT">
                            AUDIO-IMAGE-TEXT
                          </option>
                        </select>
                      </div>
                    </div>

                    {chooseQuestionType != null && (
                      <>
                        {questionType === "IMAGE-AUDIO" && (
                          <>
                            <label>Question Text:</label>
                            <input
                              type="text"
                              placeholder="Question Text"
                              name="qus_text"
                              onChange={(e) => formInputHandler(e)}
                              className="form-control"
                            />
                            <label>Choose Image:</label>
                            <input
                              type="file"
                              name="img"
                              onChange={(e) => formInputHandler(e)}
                              className="form-control"
                            />

                            <div className="row">
                              <div className="col-md-4 form-group mt-2">
                                <label>Response Audio 1:</label>
                                <input
                                  type="file"
                                  name="audio1"
                                  multiple
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-4 form-group mt-2">
                                <label>Response Text 1:</label>
                                <input
                                  type="text"
                                  placeholder="Response Text 1"
                                  name="audio_text1"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-4 form-group mt-2">
                                <label>Is Correct Response 1:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4 form-group mt-2">
                                <label>Response Audio 2:</label>
                                <input
                                  type="file"
                                  name="audio2"
                                  multiple
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-4 form-group mt-2">
                                <label>Response Text 2:</label>
                                <input
                                  type="text"
                                  placeholder="Response Text 2"
                                  name="audio_text2"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-4 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {questionType === "AUDIO-IMAGE" && (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <label>Choose Audio:</label>
                                <input
                                  type="file"
                                  name="audio"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Choose Image 1:</label>
                                <input
                                  type="file"
                                  name="img1"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 1:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Choose Image 2:</label>
                                <input
                                  type="file"
                                  name="img2"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {questionType === "AUDIO-IMAGE-TEXT" && (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <label>Choose Audio:</label>
                                <input
                                  type="file"
                                  name="audio"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-12 form-group mt-2">
                                <label>Response Image 1:</label>
                                <input
                                  type="file"
                                  name="img1"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response Text 2:</label>
                                <input
                                  type="text"
                                  name="text1"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response Text 2"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 1:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response Text 3:</label>
                                <input
                                  type="text"
                                  name="text2"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response Text 3"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {questionType === "TEXT-AUDIO" && (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <label>Question Text:</label>
                                <input
                                  type="text"
                                  name="qus_text"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Question Text"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 form-group mt-2">
                                <label>Response Audio 1:</label>
                                <input
                                  type="file"
                                  name="audio1"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response Text 1:</label>
                                <input
                                  type="text"
                                  placeholder="Response Text 1"
                                  name="audio_text1"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 1:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12 form-group mt-2">
                                <label>Response Audio 2:</label>
                                <input
                                  type="file"
                                  name="audio2"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response Text 2:</label>
                                <input
                                  type="text"
                                  placeholder="Response Text 2"
                                  name="audio_text2"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {questionType === "TEXT-TEXT" && (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <label>Question Text:</label>
                                <input
                                  type="text"
                                  name="qus_text"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Question Text"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response 1:</label>
                                <input
                                  type="text"
                                  name="text1"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response text 1"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 1:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response 2:</label>
                                <input
                                  type="text"
                                  name="text2"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response text 2"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {questionType === "AUDIO-TEXT" && (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <label>Choose Audio:</label>
                                <input
                                  type="file"
                                  name="audio"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response 1:</label>
                                <input
                                  type="text"
                                  name="text1"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response text 1"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 1:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response 2:</label>
                                <input
                                  type="text"
                                  name="text2"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response text 2"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {questionType === "IMAGE-TEXT" && (
                          <>
                            <label>Question Text:</label>
                            <input
                              type="text"
                              placeholder="Question Text"
                              name="qus_text"
                              onChange={(e) => formInputHandler(e)}
                              className="form-control"
                            />
                            <label>Choose Image:</label>
                            <input
                              type="file"
                              name="img"
                              onChange={(e) => formInputHandler(e)}
                              className="form-control"
                            />

                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response 1:</label>
                                <input
                                  type="text"
                                  name="text1"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response text 1"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Response 2:</label>
                                <input
                                  type="text"
                                  name="text2"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Response text 2"
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}

                        {questionType === "TEXT-IMAGES" && (
                          <>
                            <div className="row">
                              <div className="col-md-12">
                                <label>Question Text:</label>
                                <input
                                  type="text"
                                  name="qus_text"
                                  onChange={(e) => formInputHandler(e)}
                                  placeholder="Question Text"
                                  className="form-control"
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Choose Image 1:</label>
                                <input
                                  type="file"
                                  name="img1"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 1:</label>
                                <input
                                  type="number"
                                  name="res1_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 form-group mt-2">
                                <label>Choose Image 2:</label>
                                <input
                                  type="file"
                                  name="img2"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                              <div className="col-md-6 form-group mt-2">
                                <label>Is Correct Response 2:</label>
                                <input
                                  type="number"
                                  name="res2_is_correct"
                                  placeholder="Write 0 for incorrect and 1 for correct"
                                  onChange={(e) => formInputHandler(e)}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                    <div className="row">
                      <div className="col-md-12 mt-3">
                        <button
                          type="button"
                          disabled={buttonDisable}
                          className="btn btn-sm btn-primary"
                          onClick={() => setPreview((prev) => !prev)}
                        >
                          Preview
                        </button>
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Basic example"
                          style={{ float: "right" }}
                        >
                          <input
                            type="submit"
                            disabled={buttonDisable}
                            className="btn btn-success"
                            value="Submit"
                          />
                          <button
                            type="button"
                            disabled={buttonDisable}
                            className="btn btn-danger"
                            onClick={() => buttonClose()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  {preview === true && (
                    <AddQuestionPopUpPreview
                      questionType={questionType}
                      responses={responses}
                      responseType={responseType}
                      img1={img1}
                      img2={img2}
                      audio1={audio1}
                      audio2={audio2}
                      text1={text1}
                      text2={text2}
                      qesText={qesText}
                      audioText1={audioText1}
                      audioText2={audioText2}
                    />
                  )}
                </>
              )}
              <CloseModalButton
                aria-label="Close modal"
                onClick={() => buttonClose()}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};

export default AddQuestionPopUp;
