import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import shadeImg from "../assets/shade.jpeg";
import clickedImg from "../assets/check.png";
import data from "../assets/data/match-images";

function MatchingImages() {
  const [cardShowState, setCardShowState] = useState(true);
  const [firstSelectedImage, setFirstSelectedImage] = useState(null);
  const [previousEvent, setPreviousEvent] = useState([]);

  const setCardNumberToIndex = (e) => {
    if (firstSelectedImage === null) {
      setFirstSelectedImage(
        e.target.parentNode.parentNode.getAttribute("data")
      );
      const new_list = previousEvent.concat(e);
      setPreviousEvent(new_list);
      e.target.parentNode.parentNode.classList.add("active");
      e.target.parentNode.parentNode.parentNode.classList.add("selected");
    } else if (
      firstSelectedImage === e.target.parentNode.parentNode.getAttribute("data")
    ) {
      const new_list = previousEvent.concat(e);
      setPreviousEvent(new_list);
      e.target.parentNode.parentNode.classList.add("active");
      e.target.parentNode.parentNode.parentNode.classList.add("selected");
    } else if (
      firstSelectedImage != e.target.parentNode.parentNode.getAttribute("data")
    ) {
      e.target.parentNode.parentNode.classList.add("active");
      setTimeout(() => {
        for (let i = 0; i < previousEvent.length; i++) {
          const element = previousEvent[i];
          element.target.parentNode.parentNode.classList.remove("active");
          element.target.parentNode.parentNode.parentNode.classList.remove(
            "selected"
          );
        }
        e.target.parentNode.parentNode.classList.remove("active");
        e.target.parentNode.parentNode.parentNode.classList.remove("selected");
        setFirstSelectedImage(null);
      }, 500);
    }
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Row>
        {data.map((item, index) => (
          <Col className="mt-3" key={index} xs={12} md={6} xl={3}>
            <div className="cord-container">
              <div
                data={item.tag}
                className={cardShowState ? "thecard" : "thecard"}
                onClick={(e) => setCardNumberToIndex(e)}
              >
                <div className="cardfront">
                  <img
                    src={shadeImg}
                    width="100%"
                    height="100%"
                    style={{ borderRadius: 5 }}
                  />
                </div>

                <div className="cardback">
                  <img src={clickedImg} width="20" height="20" />
                  <h1>{item.cardText}</h1>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default MatchingImages;
