let localization = {
    "Practice": "Practice",
    "Next": "Next",
    "Skip": "Skip",
    "Back": "Back",
    "Level": "Level",
    "Unit": "Unit",
    "Lesson": "Lesson",
    "Levels": "Levels",
    "Units": "Units",
    "Lessons": "Lessons",
    "Total": "Total",
    "Seconds": "Seconds",
    "Sec": "Sec",
    "Min": "Min",
    "Minutes": "Minutes",
    "Minute": "Minute",
    "Hour": "Hour",
    "Hours": "Hours",
    "Day": "Day",
    "Days": "Days",
}

export default localization;