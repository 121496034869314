import React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { firstInit } from "../utils/DbHelpers";

const InitApp = (props) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    const appInit = async () => {
      const parm_user_id = props.match.params.user_id;
      const search = window.location.search;
      const params = new URLSearchParams(search);
      let studentName = params.get("name");
      const studentLanguageParam = params.get("lang");
      let studentLang = "eng";

      if (studentLanguageParam !== null) {
        studentLang = studentLanguageParam;
      }

      if (studentName === null) {
        studentName = "Demo User";
      }

      const is_init = await firstInit(parm_user_id, studentLang, studentName);

      if (is_init) history.push("/?name=" + studentName);
      else {
        setErrorMessage(
          "Sorry, System update Initialization failed. Please try again later"
        );
      }
    };
    appInit();
  }, [props, history]);

  return (
    <div
      style={{
        color: "#00005c",
        fontSize: "24px",
        width: "250px",
        height: "75px",
        marginTop: "25%",
        marginLeft: "calc(50% - 125px)",
      }}
    >
      {errorMessage ? <p className="text-danger">{errorMessage}</p> : ""}
    </div>
  );
};

export default InitApp;
