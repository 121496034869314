import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { MdAdd, MdRemoveRedEye, MdEdit, MdDelete } from "react-icons/md";

//Components and Utilities
import {
  saveAppState,
  getLevels,
  getInitialState,
  getUserId,
} from "../utils/DbHelpers";
import { deleteLevel } from "../utils/ApiHelpers";
import Header from "./Header";

//Design Assets and Bootstrap
import { Container, Row, Col } from "react-bootstrap";
import { FaTasks, FaLock } from "react-icons/fa";
import "../levels.scss";

//Image Assets
import long_textholder from "../assets/long_textholder.png";
import AddQuestionPopUp from "./AddQuestionPopUp";
import EditLevelUnitPopUp from "./EditLevelUnitPopUp";

const levelStyle = {
  backgroundImage: `url(${long_textholder})`,
};

const Levels = (props) => {
  const history = useHistory();
  const [levels, setLevels] = useState([]);
  const [initialData, setInitialData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editShowModal, setEditShowModal] = useState(false);
  const [keyForPopUp, setKeyForPopUp] = useState();
  const [editAndDeleteBtnView, setEditAndDeleteBtnView] = useState(false);
  const [levelData, setLevelData] = useState(null);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  useEffect(() => {
    setKeyForPopUp("Levels");
    const readLevels = async () => {
      //Fetch Levels from Db
      const levels_data = await getLevels();
      const user_id = await getUserId(getUserId);

      if (levels_data) {
        const current_app_state = {
          screen: "levels",
          level: 0,
          unit: 0,
          lesson: 0,
          question: 0,
        };
        await saveAppState("APP", current_app_state);

        const initial_data = await getInitialState();
        for (let i = 0; i < initial_data.length; ++i) {
          if (parseInt(initial_data[i].user_id) === parseInt(user_id)) {
            setInitialData(initial_data[i]);
            break;
          }
        }
        setLevels(levels_data);
      } else {
        setErrorMessage(
          "Sorry, System unable to read levels. Please try again later"
        );
      }
    };
    readLevels();
  }, []);

  const onLevelClicked = async (id) => {
    history.push(`/levels/${id}/units`);
  };

  const editBtnHandler = (e, lvlD) => {
    setLevelData(lvlD);
    setEditShowModal((prev) => !prev);
    e.preventDefault();
    console.log("this is edit btn handler....");
  };

  const deleteLevels = (lvl_id) => {
    deleteLevel(lvl_id);
  };

  return (
    <Container
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <AddQuestionPopUp
        showModal={showModal}
        setShowModal={setShowModal}
        keyForPopUp={keyForPopUp}
        props={props}
      />
      <EditLevelUnitPopUp
        editShowModal={editShowModal}
        setEditShowModal={setEditShowModal}
        levelData={levelData}
        keyForPopUp={keyForPopUp}
      />
      <Row>
        <Col xs="12">
          {errorMessage ? <p className="text-danger">{errorMessage}</p> : ""}
        </Col>
      </Row>

      <Row>
        <Col xs="12">
          <Header level_id={""} unit_id={""} lessons_id={""} />
        </Col>
      </Row>

      <Row key="r2">
        {levels.map((level, index) => {
          return (
            <Col key={`c${index}`} xs={12} md={6} xl={4}>
              {parseInt(initialData.level_id) < parseInt(level.id) ? (
                <>
                  {editAndDeleteBtnView && (
                    <>
                      <div className="edit-btn-container">
                        <button
                          className="btn btn-sm btn-link"
                          onClick={(e) => editBtnHandler(e, level)}
                        >
                          <MdEdit size={20} />
                        </button>
                        <button
                          className="btn btn-sm btn-link"
                          onClick={() => deleteLevels(level.id)}
                        >
                          <MdDelete size={20} color="red" />
                        </button>
                      </div>
                    </>
                  )}
                  <div
                    className="default-list-button locked-item"
                    style={levelStyle}
                  >
                    <div className="engraved">
                      <FaLock className="mb-1"></FaLock>
                      <p className="px-2 d-inline-block">
                        <strong>{level.title}</strong>
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {editAndDeleteBtnView && (
                    <>
                      <div className="edit-btn-container">
                        <button
                          className="btn btn-sm btn-link"
                          onClick={(e) => editBtnHandler(e, level)}
                        >
                          <MdEdit size={20} />
                        </button>
                        <button
                          className="btn btn-sm btn-link"
                          onClick={() => deleteLevels(level.id)}
                        >
                          <MdDelete size={20} color="red" />
                        </button>
                      </div>
                    </>
                  )}

                  <div
                    className="default-list-button"
                    style={levelStyle}
                    onClick={() => onLevelClicked(level.id)}
                  >
                    <div className="engraved">
                      <FaTasks className="mb-1"></FaTasks>
                      <p className="px-2 d-inline-block">
                        <strong>{level.title}</strong>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </Col>
          );
        })}
      </Row>
      <button
        className="btn btn-link add-new-btn"
        onClick={openModal}
        style={{ marginRight: 110 }}
      >
        <MdAdd className="add-new-btn-icon" /> Add New
      </button>

      <button
        className="btn btn-link add-new-btn"
        onClick={() => setEditAndDeleteBtnView((prev) => !prev)}
      >
        <MdRemoveRedEye className="add-new-btn-icon" /> Show Edit
      </button>
    </Container>
  );
};

export default Levels;
