import { Col, Row } from "react-bootstrap";
import answer_box from "../../assets/answer_box.png";
import { FaArrowCircleRight } from "react-icons/fa";
import button_correct from "../../assets/button_correct.png";
import button_wrong from "../../assets/button_wrong.png";
import answer_box_correct from "../../assets/answer_box_correct.png";

const answerBox = {
  backgroundImage: `url(${answer_box})`,
};

const textButtonCorrect = {
  backgroundImage: `url(${button_correct})`,
  cursor: "default",
};

const textButtonWrong = {
  backgroundImage: `url(${button_wrong})`,
  cursor: "default",
  opacity: 0.5,
};

const answerBoxCorrect = {
  backgroundImage: `url(${answer_box_correct})`,
  cursor: "default",
};

const answerBoxWrong = {
  backgroundImage: `url(${answer_box})`,
  cursor: "default",
  opacity: 0.5,
};

const AudioTemplate = ({
  questionResponses,
  isAnswered,
  lessonAssetPath,
  onAnswerClick,
}) => {
  function isTemplateImageDoubleText() {
    let textCount = 0;
    let imageCount = 0;

    for (let i = 0; i < questionResponses.length; ++i) {
      if (questionResponses[i].response_type === "IMAGE") {
        imageCount += 1;
      } else if (questionResponses[i].response_type === "TEXT") {
        textCount += 1;
      }
    }

    if (textCount === 2 && imageCount === 1) return true;

    return false;
  }

  return (
    <div>
      {isTemplateImageDoubleText() && (
        <div>
          <Row>
            <Col xs={12} lg={6} md={5}>
              {questionResponses.map((response, index) => (
                <div key={`audio-text-response-container-1-${index}`}>
                  {response.response_type === "IMAGE" && [
                    isAnswered
                      ? [
                          response.is_correct === 1 ? (
                            <div
                              key={`audio-text-response-text-1`}
                              className="default-answer-box"
                              style={answerBoxCorrect}
                            >
                              <img
                                alt=""
                                src={
                                  lessonAssetPath +
                                  `images/${response.response_content}`
                                }
                                style={{ height: "200px" }}
                              />
                            </div>
                          ) : (
                            <div
                              key={`audio-text-response-text-2`}
                              className="default-answer-box"
                              style={answerBox}
                            >
                              <img
                                alt=""
                                src={
                                  lessonAssetPath +
                                  `images/${response.response_content}`
                                }
                                style={{ height: "200px" }}
                              />
                            </div>
                          ),
                        ]
                      : [
                          <div
                            key={`audio-text-response-text-3`}
                            className="default-answer-box unanswered-answer-box"
                            style={answerBox}
                            onClick={() =>
                              onAnswerClick(questionResponses[0].id)
                            }
                          >
                            <img
                              alt=""
                              src={
                                lessonAssetPath +
                                `images/${response.response_content}`
                              }
                              style={{ height: "200px" }}
                            />
                          </div>,
                        ],
                  ]}
                </div>
              ))}
            </Col>
            <Col xs={12} lg={6} md={5}>
              {questionResponses.map((response, index) => (
                <div key={`audio-text-response-container-2-${index}`}>
                  {response.response_type === "TEXT" && [
                    isAnswered
                      ? [
                          response.is_correct === 1 ? (
                            <div
                              key={`row2-row1-col-d41${index}`}
                              className="default-text-button"
                              style={textButtonCorrect}
                            >
                              {response.response_content}
                            </div>
                          ) : (
                            <div
                              key={`row2-row1-col-d42${index}`}
                              className="default-text-button "
                              style={textButtonWrong}
                            >
                              {response.response_content}
                            </div>
                          ),
                        ]
                      : [
                          <div
                            key={`res-text-${index}`}
                            className="default-text-button unanswered-text-button"
                            onClick={() => onAnswerClick(response.id)}
                          >
                            <FaArrowCircleRight
                              className="animate-flicker"
                              style={{
                                fontSize: "32px",
                                marginRight: "15px",
                                color: "FFcc00",
                                float: "left",
                              }}
                            ></FaArrowCircleRight>
                            {response.response_content}
                          </div>,
                        ],
                  ]}
                </div>
              ))}
            </Col>
          </Row>
        </div>
      )}
      <Row>
        {!isTemplateImageDoubleText() &&
          questionResponses.map((response, index) => (
            <Col key={`ansblock${index}`} xs={12} lg={6}>
              {response.response_type === "TEXT"
                ? [
                    isAnswered
                      ? [
                          response.is_correct === 1 ? (
                            <div
                              key={`row2-row1-col-d41${index}`}
                              className="default-text-button"
                              style={textButtonCorrect}
                            >
                              <span className="responsive-font-style-1">
                                {response.response_content}
                              </span>
                            </div>
                          ) : (
                            <div
                              key={`row2-row1-col-d42${index}`}
                              className="default-text-button "
                              style={textButtonWrong}
                            >
                              <span className="responsive-font-style-1">
                                {response.response_content}
                              </span>
                            </div>
                          ),
                        ]
                      : [
                          <div
                            key={`res-text-${index}`}
                            className="default-text-button unanswered-text-button"
                            onClick={() => onAnswerClick(response.id)}
                          >
                            <FaArrowCircleRight
                              className="animate-flicker"
                              style={{
                                fontSize: "32px",
                                marginRight: "15px",
                                color: "FFcc00",
                                float: "left",
                              }}
                            ></FaArrowCircleRight>
                            <span className="responsive-font-style-1">
                              {response.response_content}
                            </span>
                          </div>,
                        ],
                  ]
                : [
                    response.response_type === "IMAGE" && [
                      isAnswered
                        ? [
                            response.is_correct === 1 ? (
                              <div
                                key={`row2-row1-col-d51${index}`}
                                className="default-answer-box"
                                style={answerBoxCorrect}
                              >
                                <img
                                  alt=""
                                  src={
                                    lessonAssetPath +
                                    `images/${response.response_content}`
                                  }
                                  style={{ height: "200px" }}
                                />
                              </div>
                            ) : (
                              <div
                                key={`row2-row1-col-d52${index}`}
                                className="default-answer-box"
                                style={answerBoxWrong}
                              >
                                <img
                                  alt=""
                                  src={
                                    lessonAssetPath +
                                    `images/${response.response_content}`
                                  }
                                  style={{ height: "200px" }}
                                />
                              </div>
                            ),
                          ]
                        : [
                            <div
                              key={`row2-row1-col-d62${index}`}
                              className="default-answer-box unanswered-answer-box"
                              style={answerBox}
                              onClick={() => onAnswerClick(response.id)}
                            >
                              <img
                                alt=""
                                src={
                                  lessonAssetPath +
                                  `images/${response.response_content}`
                                }
                                style={{ height: "200px" }}
                              />
                            </div>,
                          ],
                    ],
                  ]}
            </Col>
          ))}
      </Row>
    </div>
  );
};

export default AudioTemplate;
