import React, { useRef, useEffect, useCallback, useState } from "react";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { useSpring, animated } from "react-spring";
import { editLevels, editUnits } from "../utils/ApiHelpers";

const Background = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  margin: auto;
`;
const ModalWrapper = styled.div`
  width: 800px;
  max-height: 700px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 30;
  border-radius: 10px;
  overflow: scroll;
  padding-bottom: 25px;
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 30;
`;

const EditLevelUnitPopUp = ({
  editShowModal,
  setEditShowModal,
  keyForPopUp,
  unitData,
  levelData,
}) => {
  const modalRef = useRef();
  const [levelsTitle, setLevelsTitle] = useState(null);
  const [unitsTitle, setUnitsTitle] = useState(null);
  const [submitBtnEnable, setSubmitBtnEnable] = useState(true);

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: editShowModal ? 1 : 0,
    transform: editShowModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const setValuesToNull = () => {
    setLevelsTitle(null);
    setUnitsTitle(null);
    setEditShowModal(false);
  };

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setEditShowModal(false);
      setValuesToNull();
    }
  };

  const buttonClose = () => {
    setEditShowModal(false);
    setValuesToNull();
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && editShowModal) {
        setEditShowModal(false);
        setValuesToNull();
      }
    },
    [setEditShowModal, editShowModal]
  );

  useEffect(async () => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  const onFormInputChange = (e) => {
    if (keyForPopUp === "Levels") {
      if (e.target.name === "level_title") {
        setLevelsTitle(e.target.value);
        setSubmitBtnEnable(
          e.target.value && levelData.id != null ? false : true
        );
      }
    }

    if (keyForPopUp === "Units") {
      if (e.target.name === "unit_title") {
        setUnitsTitle(e.target.value);
        setSubmitBtnEnable(
          e.target.value && unitData.id != null ? false : true
        );
      }
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (keyForPopUp === "Levels") {
      const data = new FormData();
      data.append("level_id", levelData.id);
      data.append("level_title", levelsTitle);
      editLevels(data);
      setValuesToNull();
    }

    if (keyForPopUp === "Units") {
      const data = new FormData();
      data.append("unit_id", unitData.id);
      data.append("unit_title", unitsTitle);
      editUnits(data);
      setValuesToNull();
    }
  };

  return (
    <>
      {editShowModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper editShowModal={editShowModal}>
              {keyForPopUp === "Levels" && (
                <>
                  <>
                    <h3 className="form-title">Edit Levels</h3>
                    <form onSubmit={onSubmit} className="form">
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Level ID:</label>
                          <input
                            type="text"
                            name="level_id"
                            className="form-control"
                            value={levelData.id}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <label>Level Title:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="level_title"
                            onChange={(e) => onFormInputChange(e)}
                            placeholder={
                              "Edit `" + levelData.title + "'s` title"
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <input
                            type="submit"
                            disabled={submitBtnEnable}
                            className="btn btn-sm btn-primary fr-btn"
                          />
                        </div>
                      </div>
                    </form>
                  </>
                </>
              )}

              {keyForPopUp === "Units" && (
                <>
                  <>
                    <h3 className="form-title">Edit Units</h3>
                    <form onSubmit={onSubmit} className="form">
                      <div className="row">
                        <div className="col-md-12 form-group">
                          <label>Unit ID:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={unitData.id}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <label>Unit Title:</label>
                          <input
                            type="text"
                            className="form-control"
                            name="unit_title"
                            onChange={(e) => onFormInputChange(e)}
                            placeholder={
                              "Edit `" + unitData.title + "'s` title"
                            }
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12 mt-3">
                          <input
                            type="submit"
                            disabled={submitBtnEnable}
                            className="btn btn-sm btn-primary fr-btn"
                          />
                        </div>
                      </div>
                    </form>
                  </>
                </>
              )}

              <CloseModalButton
                aria-label="Close modal"
                onClick={() => buttonClose()}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};

export default EditLevelUnitPopUp;
