import React, { useEffect, useState, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";
import { fetchGameData } from "../../utils/ApiHelpers";
import { getUserId } from "../../utils/DbHelpers";

const StartGame = () => {
  const history = useHistory();
  const location = useLocation();
  const [userScore, setUserScore] = useState(0);
  const componentMounted = useRef(true);

  const startGame = () => {
    let state = null;
    if (location.state) {
      state = {
        level_id: location.state.level_id,
        unit_id: location.state.unit_id,
        lesson_id: location.state.lesson_id,
        question_order_no: location.state.question_order_no,
      };
    } else {
      state = {
        level_id: 1,
        unit_id: 1,
        lesson_id: 1,
        question_order_no: 1,
      };
    }

    history.push({ pathname: "/simon-game", state: state });
  };

  useEffect(() => {
    const getGameData = async () => {
      let user_id = await getUserId();
      let game_data = await fetchGameData(user_id);
      localStorage.setItem("gameData", JSON.stringify(game_data.game));
      if (game_data.user_score.length) {
        localStorage.setItem("userScore", game_data.user_score[0].score);
      }

      if (componentMounted.current) {
        {
          game_data.user_score.length &&
            setUserScore(game_data.user_score[0].score);
        }
      }
    };

    getGameData();

    return () => {
      componentMounted.current = false;
    };
  }, [userScore]);

  return (
    <div className="container-fluid si-body">
      <div className="game-title-container">
        <h1>GAME TIME</h1>
      </div>

      <div className="game-play-btn-container">
        <span className="play-btn" onClick={() => startGame()}>
          <b>PLAY</b>
        </span>
      </div>
    </div>
  );
};

export default StartGame;
