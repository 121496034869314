import { Col, Row } from "react-bootstrap";

import button_replay from "../../assets/button-replay-2.png";
import button_replay_correct from "../../assets/button-replay-1.png";
import answer_box from "../../assets/answer_box.png";
import { FaArrowCircleRight } from "react-icons/fa";
import button_correct from "../../assets/button_correct.png";
import button_wrong from "../../assets/button_wrong.png";

const answerBox = {
  backgroundImage: `url(${answer_box})`,
};

const textButtonCorrect = {
  backgroundImage: `url(${button_correct})`,
  cursor: "default",
};

const textButtonWrong = {
  backgroundImage: `url(${button_wrong})`,
  cursor: "default",
  opacity: 0.5,
};

const ImageTemplate = ({
  questionResponses,
  isAnswered,
  lessonAssetPath,
  onAnswerClick,
  onClickPlayAudio,
  showingConfetti,
  question,
}) => {
  return (
    <div>
      <Row>
        <Col key="qb" xs={12} lg={6}>
          <div key="qb-div" className="default-answer-box" style={answerBox}>
            <img
              key="qb-div-img"
              alt=""
              src={lessonAssetPath + `images/${question.question_content}`}
              style={{ height: "200px" }}
            />
          </div>
        </Col>

        <Col key="qb2" xs={12} lg={6}>
          <div
            key="qb2-div"
            className={
              question.question_type === "IMAGE-AUDIO"
                ? "image-text-audio-block"
                : ""
            }
          >
            {questionResponses.map((response, index) => (
              <div key={`b-res-text-${index}`} style={{ width: "100%" }}>
                {response.response_type === "TEXT" && [
                  isAnswered
                    ? [
                        response.is_correct === 1 ? (
                          <div
                            key={`row2-row1-col-d21${index}`}
                            className="default-text-button "
                            style={textButtonCorrect}
                          >
                            <span className="responsive-font-style-3">
                              {response.response_content}
                            </span>
                          </div>
                        ) : (
                          <div
                            key={`row2-row1-col-d22${index}`}
                            className="default-text-button "
                            style={textButtonWrong}
                          >
                            <span className="responsive-font-style-3">
                              {response.response_content}
                            </span>
                          </div>
                        ),
                      ]
                    : [
                        <div
                          key={`res-text-${index}`}
                          className="default-text-button unanswered-text-button"
                          onClick={() => onAnswerClick(response.id)}
                        >
                          <FaArrowCircleRight
                            className="animate-flicker"
                            style={{
                              fontSize: "32px",
                              marginRight: "15px",
                              color: "FFcc00",
                              float: "left",
                            }}
                          ></FaArrowCircleRight>
                          <span className="responsive-font-style-3">
                            {response.response_content}
                          </span>
                        </div>,
                      ],
                ]}
                {response.response_type === "AUDIO" && (
                  <div
                    key={`res-audio-${index}`}
                    className="image-audio-response-choices"
                  >
                    {isAnswered
                      ? [
                          response.is_correct === 1 ? (
                            <img
                              key={`row2-row1-col-d9${index}`}
                              alt=""
                              src={button_replay_correct}
                              onClick={() =>
                                onClickPlayAudio(
                                  lessonAssetPath +
                                    `audio/${response.response_content}`
                                )
                              }
                            />
                          ) : (
                            <img
                              style={{ opacity: 0.5 }}
                              key={`row2-row1-col-d9${index}`}
                              alt=""
                              src={button_replay}
                              onClick={() =>
                                onClickPlayAudio(
                                  lessonAssetPath +
                                    `audio/${response.response_content}`
                                )
                              }
                            />
                          ),
                        ]
                      : [
                          showingConfetti && response.is_correct === 1
                            ? [
                                // <img key={`row2-row1-col-d9${index}`} alt="" src={button_replay_correct} onClick={() => onClickPlayAudio(lessonAssetPath + `audio/${response.response_content}`)} />
                                <div
                                  className="audio-correct-button-container"
                                  id={`audio-response-${index}`}
                                ></div>,
                              ]
                            : [
                                <div
                                  className="audio-button-container"
                                  id={`audio-response-${index}`}
                                >
                                  <div
                                    className="audio-play-box"
                                    onClick={() =>
                                      onClickPlayAudio(
                                        lessonAssetPath +
                                          `audio/${response.response_content}`,
                                        `audio-response-${index}`
                                      )
                                    }
                                    onMouseDown={(e) =>
                                      (e.target.parentNode.src = button_replay)
                                    }
                                  ></div>
                                  <div
                                    className="audio-select-box"
                                    onClick={() => onAnswerClick(response.id)}
                                  ></div>
                                </div>,
                              ],
                        ]}

                    {isAnswered
                      ? [
                          // response.is_correct === 1 ?
                          // <div  key={`row2-row1-col-d31${index}`} className="default-text-button" style={textButtonCorrect}>
                          //     {response.response_content_label}Test
                          // </div>
                          // :
                          // <div  key={`row2-row1-col-d32${index}`} className="default-text-button " style={textButtonWrong}>
                          //     {response.response_content_label}
                          // </div>
                        ]
                      : [
                          // <div key={`res-text-${index}`} className="default-text-button unanswered-text-button "   onClick={() => onAnswerClick(response.id)}>
                          // <FaArrowCircleRight className="animate-flicker" style={{fontSize: "32px", marginRight:"15px", color: "FFcc00", float:"left"}}></FaArrowCircleRight>
                          //   {/* <span className="responsive-font-style-1">{response.response_content_label}</span> */}
                          //   <span className="responsive-font-style-2">{response.response_content_label}</span>
                          // </div>
                        ]}
                  </div>
                )}
              </div>
            ))}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ImageTemplate;
