import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import Dexie from "dexie";

import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { MdEdit, MdAdd } from "react-icons/md";
import "../levels.scss";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import Circles from "react-loading-icons/dist/components/circles";
import AnswersMeter from "./AnswersMeter";
import PopUpModal from "./PopUpModal";
import AddQuestionPopUp from "./AddQuestionPopUp";
import { saveLiveCounterInDb } from "../utils/ApiHelpers";

// QUestion templates
import TextTemplate from "./templates/TextTemplate";
import ImageTemplate from "./templates/ImageTemplate";
import AudioTemplate from "./templates/AudioTemplate";

//Utilities
import {
  fetchUnitLessons,
  getUserLessonProgress,
  getLessonS3Data,
} from "../utils/ApiHelpers";
import {
  saveUnitLessons,
  saveAppState,
  getUserId,
  getUnitIdByLesson,
  getLevelIdByUnit,
  getLessonSortOrder,
  checkIsAdmin,
} from "../utils/DbHelpers";
import { processAnswer } from "../utils/ProjectFunctions";

//Assets
import lesson_button from "../assets/lesson_button.png";
import text_button from "../assets/text_button.png";
import button_replay from "../assets/button-replay-2.png";
import audio_long from "../assets/long_play_button.png";
import audio_long_down from "../assets/long_play_button_down.png";

import { ASSETS_BASE_PATH } from "../constants/constants";

import Swal from "sweetalert2";
import good_job from "../assets/goodjob.png";
import Confetti from "react-confetti";

const db = new Dexie("EllStudyApp");
db.version(1).stores({
  stateManagement: "state",
  initialData: "user_id",
  studyLevels: "id, sort_order",
  studyUnits: "id, level_id, sort_order",
  studyLessons: "id, unit_id, sort_order",
  studyQuestions: "id, lesson_id, sort_order",
  studyResponses: "id, lesson_id, question_id",
});

const buttonStyle = {
  backgroundImage: `url(${text_button})`,
};

const Question = (props) => {
  const history = useHistory();
  const [level, setLevel] = useState(null);
  const [unit, setUnit] = useState();
  const [lesson, setLesson] = useState();
  const [question, setQuestion] = useState([]);
  const [currentQuestionOrder, setQuestionSortOrder] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState();
  const [isAnswered, setIsAnswered] = useState(false);

  const [questionDetails, setQuestionDetails] = useState([]);
  const [lessonQuestions, setLessonQuestions] = useState([]);
  const [questionResponses, setQuestionResponses] = useState([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalMessage, setModalMessage] = useState();
  const [systemUpdate, setSystemUpdate] = useState(false);
  const [animationState, setAnimationState] = useState(false);
  const [userId, setUserId] = useState(0);
  const [lessonAssetPath, setLessonAssetPath] = useState("");
  const [showingConfetti, setConfetti] = useState(false);
  const [showNavButtons, setShowNav] = useState(false);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [lessonSort, setLessonSort] = useState(1);
  const [isAdmin, setAdmin] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [showAddNewModal, setShowAddNewModal] = useState(false);
  const [keyForPopUp, setKeyForPopUp] = useState();
  const [gameTime, setGameTime] = useState(0);

  const openModal = () => {
    setShowModal((prev) => !prev);
  };

  const openAddNewModal = () => {
    setShowAddNewModal((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem("live_counter", 0);
    setKeyForPopUp("Questions");
    const readQuestion = async () => {
      setQuestion([]);
      setQuestionDetails([]);
      setIsAnswered(false);
      setShowNav(false);
      setConfetti(false);
      setLoading(false);

      const param_level_id = parseInt(props.match.params.level_id);
      const param_unit_id = parseInt(props.match.params.unit_id);
      const param_lesson_id = parseInt(props.match.params.lesson_id);
      const param_que_order_no = parseInt(props.match.params.question_order_no);
      const user_id = await getUserId();
      const admin = await checkIsAdmin();

      if (admin === true) {
        setAdmin(true);
      }

      setUserId(user_id);

      //Check if Unit and Level is same for this new lesson
      const new_unit_id = await getUnitIdByLesson(param_lesson_id);
      const new_level_id = await getLevelIdByUnit(new_unit_id);

      // Make sure all params are actual numbers
      if (
        isNaN(param_lesson_id) ||
        isNaN(param_level_id) ||
        isNaN(param_unit_id)
      ) {
        window.location.href = "/"; // Redirect to root to restart the application
        return;
      }

      let localLessonId = await getLessonSortOrder(
        param_lesson_id,
        param_unit_id
      );
      setLessonSort(localLessonId);
      setLessonAssetPath(
        ASSETS_BASE_PATH +
          `/level-${parseInt(props.match.params.level_id)}/unit-${parseInt(
            props.match.params.unit_id
          )}/lesson-${localLessonId}/`
      );

      if (parseInt(new_unit_id) > 0 || parseInt(new_level_id) > 0) {
        if (
          parseInt(new_unit_id) !== param_unit_id ||
          parseInt(new_level_id) !== param_level_id
        ) {
          // Redirect to New Path
          history.push(
            `/levels/${new_level_id}/units/${new_unit_id}/lessons/${param_lesson_id}/questions/1`
          );
          return;
        }
      }

      setLevel(param_level_id);
      setUnit(param_unit_id);
      setLesson(param_lesson_id);

      //Read All Questions
      const lessonQuestions_data = await db.studyQuestions
        .where({ lesson_id: param_lesson_id })
        .sortBy("sort_order");

      // Total Question
      const totalQuestions = lessonQuestions_data.length;

      if (totalQuestions > 0) {
        /*
            Set the question score to the users score.
            
            0: Has not started
            -1: Wrong
            1: Correct
          */
        const userLessonProgress = await getUserLessonProgress(
          user_id,
          param_lesson_id
        );
        for (let i = 0; i < lessonQuestions_data.length; ++i) {
          if (lessonQuestions_data[i].id in userLessonProgress.progress) {
            lessonQuestions_data[i].score =
              userLessonProgress.progress[lessonQuestions_data[i].id].score;
          } else {
            lessonQuestions_data[i].score = 0;
          }
        }

        let paramQuestion = param_que_order_no - 1;

        // Make sure question isn't out of bounds
        if (paramQuestion < 0) {
          paramQuestion = 0; // Put user at the first question
        } else if (paramQuestion >= lessonQuestions_data.length) {
          paramQuestion = lessonQuestions_data.length - 1; // Put user at the last question
        }

        // Get First Question
        const activeQuestion = lessonQuestions_data[paramQuestion];
        const activeQuestionId = activeQuestion.id;

        const questionDetails_data = await db.studyQuestions
          .where({ lesson_id: param_lesson_id, id: activeQuestionId })
          .toArray();
        const questionResponses_data = await db.studyResponses
          .where({ lesson_id: param_lesson_id, question_id: activeQuestionId })
          .toArray();

        if (parseInt(activeQuestion.score) === 0) {
          setIsAnswered(false);
        } else {
          setIsAnswered(true);
        }

        // Used by API to save users location and unlock lessons / units
        activeQuestion.unit_id = param_unit_id;
        activeQuestion.level_id = param_level_id;
        activeQuestion.is_last_question =
          activeQuestion.sort_order === lessonQuestions_data.length;

        setQuestion(activeQuestion);
        setLessonQuestions(lessonQuestions_data);
        setQuestionDetails(questionDetails_data[0]);
        setQuestionResponses(questionResponses_data);
        setTotalQuestions(totalQuestions);
        setQuestionSortOrder(activeQuestion.sort_order - 1);
        const current_app_state = {
          screen: "questions",
          level: param_level_id,
          unit: param_unit_id,
          lesson: param_lesson_id,
          question: param_que_order_no,
        };
        await saveAppState("APP", current_app_state);

        // The user is now starting the second set of questions
        if (paramQuestion === 10 && parseInt(activeQuestion.score) === 0) {
          Swal.fire({
            imageUrl: good_job,
            confirmButtonText: "Continue →",
            timer: 5000,
          });
        }

        document.removeEventListener("click", mouseHandler);
        document.addEventListener("click", mouseHandler);
        setTimeout(() => {
          setShowNav(true);
        }, 300);
      } else {
        setModalShow(true);
        setTotalQuestions(0);

        var param_unit = props.match.params.unit_id;
        param_unit = parseInt(param_unit);

        setUnit(param_unit);
        setModalMessage("Please wait, system update is in progress");
        setSystemUpdate(true);

        try {
          if (param_unit) {
            const unitLessons = await fetchUnitLessons(
              param_unit,
              param_lesson_id,
              user_id
            );
            if (unitLessons) {
              const dataSaved = await saveUnitLessons(unitLessons);
              if (dataSaved) {
                //setModalShow(false)
                window.location.reload();
              }
            }
          }
        } catch (error) {
          console.log("error fetching units");
        }
        //setSystemUpdate(false)
      }
    };

    if (gameTime === 5) {
      Swal.fire({
        title: "Game Time! Are you want to play?",
        showDenyButton: true,
        confirmButtonText: "Play",
        denyButtonText: `Cancel`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          setGameTime(0);
          history.push({
            pathname: "/start-game",
            state: {
              level_id: props.match.params.level_id,
              unit_id: props.match.params.unit_id,
              lesson_id: props.match.params.lesson_id,
              question_order_no: props.match.params.question_order_no,
            },
          });
        } else if (result.isDenied) {
          saveLiveCounterInDb(userId);
          setGameTime(0);
        }
      });
    }

    setAnimationState(false);
    const timeoutID = window.setTimeout(() => {
      setAnimationState(true);
      readQuestion();
    }, 50);

    return () => window.clearTimeout(timeoutID);
  }, [history, props.location, props]);

  const onAnswerClick = async (response_id) => {
    if (isAnswered) return;

    if (isLoading) return;

    setLoading(true);

    var new_question = parseInt(question.sort_order);
    new_question = new_question + 1;

    const currentResponse = questionResponses.filter(
      (response) => response.id === response_id
    );

    //Update Answer
    if (isAnswered === false) {
      var question_cp = question;
      if (parseInt(currentResponse[0].is_correct) === 1) {
        question_cp.score = 1;
        setGameTime(gameTime + 1);
        setConfetti(true);
      } else {
        question_cp.score = -1;
        setConfetti(false);
        setGameTime(0);
      }

      //Push Answer to Server and Update in DB
      await processAnswer(question_cp, unit, userId);
    }

    //Move to Chant
    if (new_question > totalQuestions) {
      if (question.is_last_question) {
        // Check if this is the last lesson in the unit
        const next_unit_id = await getUnitIdByLesson(lesson + 1);
        let finishedUnit = false;

        if (next_unit_id > unit) {
          finishedUnit = true;
        }

        if (finishedUnit) {
          history.push(
            `/levels/${level}/units/${unit}/lessons/${lesson}/chant?finished-unit=true`
          );
          return;
        }
      }

      history.push(`/levels/${level}/units/${unit}/lessons/${lesson}/chant`);
      return;
    }

    if (question_cp.score === 1 && !isAnswered) {
      setTimeout(() => {
        //Move to Next Question
        history.push(
          `/levels/${level}/units/${unit}/lessons/${lesson}/questions/${new_question}`
        );
        return;
      }, 750);
    } else {
      //Move to Next Question
      history.push(
        `/levels/${level}/units/${unit}/lessons/${lesson}/questions/${new_question}`
      );
      return;
    }
  };

  const onArrowClicked = async (arrow_direction) => {
    var new_question = parseInt(question.sort_order);

    if (arrow_direction === "left" && new_question > 1)
      new_question = new_question - 1;
    else if (arrow_direction === "left" && new_question === 1) {
      // Go to phonics if exists
      let phonics_data = await getLessonS3Data(
        lessonAssetPath + `audio/phonic.json`
      );

      if (phonics_data && Object.keys(phonics_data).length > 0) {
        history.push(
          `/levels/${level}/units/${unit}/lessons/${lesson}/phonics`
        );
        return;
      } else {
        history.push(
          `/levels/${level}/units/${unit}/lessons/${lesson}/welcome`
        );
      }

      return;
    } else new_question = new_question + 1;

    //Update Answer
    if (isAnswered === false && arrow_direction === "right") {
      var question_cp = question;
      question_cp.score = -1;

      //Push Answer to Server and Update in DB
      await processAnswer(question_cp, unit, userId);
    }

    let new_lesson = parseInt(lesson) + 1;

    //Move to Next Lesson
    if (new_question > totalQuestions) {
      history.push(`/levels/${level}/units/${unit}/lessons/${lesson}/chant`);
      return;
    }

    //Move to Previous Lesson
    if (new_question <= 0) {
      new_lesson = parseInt(lesson) - 1;
      if (new_lesson <= 0) {
        history.push(`/levels/${level}/units/${unit}/lessons`);
        return;
      }

      //Move to Next/Previous Question
      const newLessonQuestions = await db.studyQuestions
        .where({ lesson_id: new_lesson })
        .sortBy("sort_order");
      const newLessonQuestionsCount = newLessonQuestions.length;
      history.push(
        `/levels/${level}/units/${unit}/lessons/${new_lesson}/questions/${newLessonQuestionsCount}`
      );
      return;
    }

    history.push(
      `/levels/${level}/units/${unit}/lessons/${lesson}/questions/${new_question}`
    );
    return;
  };

  const onClickPlayAudio = async (audio_file, div_id = null) => {
    let audio = new Audio(audio_file);
    audio.play();

    if (div_id !== null && !isAnswered) {
      document.getElementById(
        div_id
      ).style.backgroundImage = `url(${button_replay})`;
    }
  };

  const onLessonClick = async (arrow_direction) => {
    history.push(`/levels/${level}/units/${unit}/lessons`);
  };

  const handleClose = async (response_id) => {
    if (systemUpdate) {
      setModalMessage("Please wait, system update has not finished yet");
    } else {
      setModalShow(false);
    }
  };

  const handleExit = async (response_id) => {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  function AnswerCorrectConfetti() {
    let src = {
      x: mouseX - 50,
      y: mouseY - 100,
      w: 100,
      h: 100,
    };

    return (
      showingConfetti && (
        <Confetti
          numberOfPieces={50}
          confettiSource={src}
          recycle={false}
          onConfettiComplete={onConfettiComplete}
        />
      )
    );
  }

  function mouseHandler(e) {
    e = e || window.event;
    var pageX = e.pageX;
    var pageY = e.pageY;

    setMouseX(pageX);
    setMouseY(pageY);
  }

  function onConfettiComplete() {
    setConfetti(false);
  }

  return (
    <>
      <AnswerCorrectConfetti />
      {totalQuestions ? (
        <div>
          <PopUpModal
            showModal={showModal}
            setShowModal={setShowModal}
            question={question}
            responses={questionResponses}
            lessonSort={lessonSort}
          />
          <AddQuestionPopUp
            showModal={showAddNewModal}
            setShowModal={setShowAddNewModal}
            question={question}
            responses={questionResponses}
            keyForPopUp={keyForPopUp}
            props={props}
          />
          <Container
            key="ct1"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Row key="rw1" className="questions-header-container">
              <Col key="rw1-cl1">
                <div key="rw1-cl1-d1" xs={12} className="questions-header">
                  <div key="rw1-cl1-d11" className="audio-button">
                    <img
                      alt=""
                      src={lesson_button}
                      onClick={() => onLessonClick(lesson)}
                    />
                  </div>

                  <div key="rw1-cl1-d3" className="answers-meter">
                    <AnswersMeter
                      lessonQuestions={lessonQuestions}
                      currentQuestion={currentQuestionOrder}
                      userId={userId}
                    />
                  </div>
                </div>
              </Col>
            </Row>

            <Row key="rw2" className="response-block">
              <Col
                key="rw2-cl1"
                className="links-xl arrow-buttons-block"
                xs={1}
                xl={2}
              ></Col>

              <Col key="rw2-cl2" xs={12} xl={8}>
                <Row
                  key="row2-row1"
                  className={
                    animationState ? "animate__animated animate__zoomIn" : ""
                  }
                >
                  <Col key="row2-row1-col" xs={12} className="text-center">
                    {question.question_type === "AUDIO-TEXT" ||
                    question.question_type === "AUDIO-IMAGE" ? (
                      <img
                        className="mb-3"
                        alt=""
                        src={audio_long}
                        style={{
                          width: "450px",
                          maxWidth: "80%",
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          onClickPlayAudio(
                            lessonAssetPath +
                              "audio/" +
                              question.question_content
                          )
                        }
                        onTouchStart={(e) =>
                          (e.currentTarget.src = audio_long_down)
                        }
                        onMouseDown={(e) =>
                          (e.currentTarget.src = audio_long_down)
                        }
                        onMouseUp={(e) => (e.currentTarget.src = audio_long)}
                        onTouchEnd={(e) => (e.currentTarget.src = audio_long)}
                      />
                    ) : (
                      <div
                        key="row2-row1-col-d1"
                        className="responsive-font-style-1 questions-text-button mb-3"
                        style={buttonStyle}
                      >
                        {questionDetails.question_title}
                      </div>
                    )}
                  </Col>

                  {(question.question_type === "TEXT-TEXT" ||
                    question.question_type === "TEXT-IMAGES" ||
                    question.question_type === "TEXT-AUDIO") && (
                    <>
                      <TextTemplate
                        questionResponses={questionResponses}
                        isAnswered={isAnswered}
                        lessonAssetPath={lessonAssetPath}
                        onAnswerClick={onAnswerClick}
                        onClickPlayAudio={onClickPlayAudio}
                        showingConfetti={showingConfetti}
                      />
                    </>
                  )}
                  {(question.question_type === "IMAGE-TEXT" ||
                    question.question_type === "IMAGE-AUDIO") && (
                    <>
                      <ImageTemplate
                        questionResponses={questionResponses}
                        isAnswered={isAnswered}
                        lessonAssetPath={lessonAssetPath}
                        onAnswerClick={onAnswerClick}
                        onClickPlayAudio={onClickPlayAudio}
                        showingConfetti={showingConfetti}
                        question={question}
                      />
                    </>
                  )}

                  {(question.question_type === "AUDIO-TEXT" ||
                    question.question_type === "AUDIO-IMAGE") && (
                    <>
                      <AudioTemplate
                        questionResponses={questionResponses}
                        isAnswered={isAnswered}
                        lessonAssetPath={lessonAssetPath}
                        onAnswerClick={onAnswerClick}
                      />
                    </>
                  )}
                </Row>
                {isAdmin && (
                  <>
                    <button
                      type="button"
                      className="btn btn-link"
                      onClick={openModal}
                      style={{ position: "absolute", right: "20%", top: "22%" }}
                    >
                      <MdEdit /> Edit
                    </button>
                    <button
                      className="btn btn-link"
                      onClick={openAddNewModal}
                      style={{ position: "absolute", right: "25%", top: "22%" }}
                    >
                      <MdAdd className="add-new-btn-icon" /> Add New
                    </button>
                  </>
                )}
              </Col>

              <Col
                key="rw2-cl3"
                className="links-xl arrow-buttons-block"
                xs={1}
                xl={2}
              ></Col>
            </Row>
            <Row key="rw3">
              <Col key="rw3-cl1" xs={6}>
                {showNavButtons && (
                  <div className="question-nav-container">
                    <div
                      className="question-nav-icon"
                      onClick={() => onArrowClicked("left")}
                    >
                      <FaArrowLeft></FaArrowLeft>
                    </div>
                  </div>
                )}
              </Col>
              <Col key="rw3-cl2" xs={6}>
                {isAnswered === true && showNavButtons && (
                  <div className="question-nav-container">
                    <div
                      className="question-nav-icon "
                      onClick={() => onArrowClicked("right")}
                    >
                      <FaArrowRight></FaArrowRight>
                    </div>
                  </div>
                )}
                {isAnswered === false && showNavButtons && (
                  <div className="question-nav-container">
                    <div className="question-nav-icon audio-button-incorrect">
                      <FaArrowRight></FaArrowRight>
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          </Container>
          <Container key="ct2"></Container>
        </div>
      ) : (
        <Modal
          show={modalShow}
          onHide={handleClose}
          onExited={handleExit}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>System Update</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <Circles
              stroke="#56ba00"
              fill="#56ba00"
              strokeOpacity={0.125}
              speed={0.75}
              width="50px"
              height="50px"
            />
            <p>{modalMessage}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

export default Question;
