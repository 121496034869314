import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../levels.scss";

import long_textholder from "../assets/long_textholder.png";
import lessons_button from "../assets/lessons_button.png";
import toggle_button from "../assets/lesson_button.png";
import { useState, useEffect } from "react";
import { getUserLocalization, getUser } from "../utils/DbHelpers";
import profileImg from "../assets/user.png";
import settingsImg from "../assets/settings.png";
import { LMS_URL, ASSETS_BASE_PATH } from "../constants/constants";

const headerSideButtons = {
  backgroundImage: `url(${long_textholder})`,
};

const headerDisabledSideButtons = {
  backgroundImage: `url(${long_textholder})`,
};

const headerMiddleButton = {
  backgroundImage: `url(${lessons_button})`,
};

const Header = ({ level_id, unit_id, lesson_id }) => {
  const history = useHistory();
  const [showHeader, setshowHeader] = useState(false);
  const [localization, setLocalization] = useState("");
  const [profileToggle, setProfileToggle] = useState(true);
  const [username, setUsername] = useState(null);

  useEffect(async () => {
    const user = await getUser();
    setUsername(user.name);

    let isMounted = true;

    const init = async () => {
      let local = await getUserLocalization();

      if (isMounted) setLocalization(local);
    };
    init();

    return () => {
      isMounted = false;
    };
  }, []);

  function OnLevelClick(level_id, unit_id) {
    history.push(`/levels`);
  }

  function OnUnitClick(level_id, unit_id) {
    history.push(`/levels/${level_id}/units`);
  }

  function OnLessonButtonClick(level_id, unit_id) {
    history.push(`/landing`);
  }

  function OnLessonClick(level_id, unit_id) {
    history.push(`/levels/${level_id}/units/${unit_id}/lessons`);
  }

  function toggleHeader() {
    setshowHeader(!showHeader);
  }

  function logout() {
    window.location.href = LMS_URL;
  }

  return (
    <div>
      <div
        className="header-toggle audio-button"
        onClick={() => toggleHeader()}
      >
        <img alt="header toggle button" src={toggle_button} />
      </div>
      {showHeader && (
        <Row className="header-block" key="r1">
          <Col xs={12} lg={4} className="header-first ">
            <div className="header-first-btn">
              {level_id ? (
                <div
                  className="header-side-buttons"
                  style={headerSideButtons}
                  onClick={() => OnLevelClick(level_id, unit_id)}
                >
                  {localization.Level}-{level_id}
                </div>
              ) : (
                <div
                  className="header-side-buttons  header-disabled-button"
                  style={headerSideButtons}
                >
                  {localization.Levels}{" "}
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} lg={4} className="header-middle">
            <div className="header-middle-btn">
              {lesson_id ? (
                <div
                  className="header-middle-button"
                  style={headerMiddleButton}
                  onClick={() => OnLessonClick(level_id, unit_id)}
                >
                  {localization.Lessons}
                </div>
              ) : (
                <div
                  className="header-middle-button header-disabled-button"
                  onClick={() => OnLessonButtonClick()}
                  style={headerMiddleButton}
                >
                  {localization.Lessons}
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} lg={4} className="header-end">
            <div className="header-end-btn">
              {unit_id ? (
                <div
                  className="header-side-buttons"
                  style={headerDisabledSideButtons}
                  onClick={() => OnUnitClick(level_id, unit_id)}
                >
                  {localization.Unit}-{unit_id}{" "}
                </div>
              ) : (
                <div
                  className="header-side-buttons header-disabled-button"
                  style={headerDisabledSideButtons}
                >
                  {localization.Units}{" "}
                </div>
              )}
            </div>
          </Col>
        </Row>
      )}
      <div className="action">
        <div
          className="username-container"
          onClick={() => setProfileToggle((prev) => !prev)}
        >
          <h4>{username}</h4>
        </div>
        <div className={profileToggle ? "menu" : "menu active"}>
          {/* <h6>Profile & Settings</h6> */}
          <ul>
            <li onClick={() => logout()}>
              <img src={settingsImg} />
              <a href="#">Logout</a>
            </li>
            {/* <li>
                            <img src={profileImg}/>
                            <a href="#">Profile</a>
                        </li> */}
            {/* <li>
                            <img src={settingsImg}/>
                            <a href="#">Settings</a>
                        </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;
