const data = [
    {
        tag: 'groupA',
        cardText: 'Cat'
    },
    {
        tag: 'groupB',
        cardText: 'Dog'
    },
    {
        tag: 'groupA',
        cardText: 'Cat'
    },
    {
        tag: 'groupB',
        cardText: 'Dog'
    },
    {
        tag: 'groupA',
        cardText: 'Cat'
    },
    {
        tag: 'groupB',
        cardText: 'Dog'
    }
  ];
  
  export default data;