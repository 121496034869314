import { Col, Row } from "react-bootstrap";

import button_replay from "../../assets/button-replay-2.png";
import button_replay_correct from "../../assets/button-replay-1.png";
import answer_box from "../../assets/answer_box.png";
import answer_box_correct from "../../assets/answer_box_correct.png";

const answerBox = {
  backgroundImage: `url(${answer_box})`,
};

const answerBoxCorrect = {
  backgroundImage: `url(${answer_box_correct})`,
  cursor: "default",
};

const answerBoxWrong = {
  backgroundImage: `url(${answer_box})`,
  cursor: "default",
  opacity: 0.5,
};

const TextTemplate = ({
  questionResponses,
  isAnswered,
  lessonAssetPath,
  onAnswerClick,
  onClickPlayAudio,
  showingConfetti,
}) => {
  return (
    <div>
      <Row>
        {questionResponses.map((response, index) => (
          <Col key={`row2-row1-col${index}`} xs={12} lg={6}>
            {response.response_type === "BIG-TEXT" && [
              isAnswered
                ? [
                    response.is_correct === 1 ? (
                      <div
                        key={`row2-row1-col-d1${index}`}
                        className="default-answer-box"
                        style={answerBoxCorrect}
                      >
                        <span style={{ color: "#FF00cc" }}>
                          {" "}
                          {response.response_content}
                        </span>
                      </div>
                    ) : (
                      <div
                        key={`row2-row1-col-d1${index}`}
                        className="default-answer-box"
                        style={answerBoxWrong}
                      >
                        <span style={{ color: "#FF00cc" }}>
                          {" "}
                          {response.response_content}
                        </span>
                      </div>
                    ),
                  ]
                : [
                    <div
                      key={`row2-row1-col-d2${index}`}
                      className="default-answer-box unanswered-answer-box"
                      style={answerBox}
                      onClick={() => onAnswerClick(response.id)}
                    >
                      <span style={{ color: "#FF00cc" }}>
                        {" "}
                        {response.response_content}{" "}
                      </span>
                    </div>,
                  ],
            ]}
            {response.response_type === "IMAGE" && [
              isAnswered
                ? [
                    response.is_correct === 1 ? (
                      <div
                        key={`row2-row1-col-d3${index}`}
                        className="default-answer-box"
                        style={answerBoxCorrect}
                      >
                        <img
                          alt=""
                          src={
                            lessonAssetPath +
                            `images/${response.response_content}`
                          }
                          style={{ height: "200px" }}
                        />
                      </div>
                    ) : (
                      <div
                        key={`row2-row1-col-d3${index}`}
                        className="default-answer-box"
                        style={answerBoxWrong}
                      >
                        <img
                          alt=""
                          src={
                            lessonAssetPath +
                            `images/${response.response_content}`
                          }
                          style={{ height: "200px" }}
                        />
                      </div>
                    ),
                  ]
                : [
                    <div
                      key={`row2-row1-col-d4${index}`}
                      className="default-answer-box unanswered-answer-box"
                      style={answerBox}
                      onClick={() => onAnswerClick(response.id)}
                    >
                      <img
                        alt=""
                        src={
                          lessonAssetPath +
                          `images/${response.response_content}`
                        }
                        style={{ height: "200px" }}
                      />
                    </div>,
                  ],
            ]}
            {response.response_type === "TEXT" && [
              isAnswered
                ? [
                    response.is_correct === 1 ? (
                      <div
                        key={`row2-row1-col-d5${index}`}
                        className="default-answer-box responsive-font-style-1"
                        style={answerBoxCorrect}
                      >
                        <span style={{ color: "#FF00cc" }}>
                          {" "}
                          {response.response_content}{" "}
                        </span>
                      </div>
                    ) : (
                      <div
                        key={`row2-row1-col-d6${index}`}
                        className="default-answer-box responsive-font-style-1"
                        style={answerBoxWrong}
                      >
                        <span style={{ color: "#FF00cc" }}>
                          {" "}
                          {response.response_content}{" "}
                        </span>
                      </div>
                    ),
                  ]
                : [
                    <div
                      key={`row2-row1-col-d7${index}`}
                      className="default-answer-box unanswered-answer-box responsive-font-style-1"
                      style={answerBox}
                      onClick={() => onAnswerClick(response.id)}
                    >
                      <span style={{ color: "#FF00cc" }}>
                        {" "}
                        {response.response_content}
                      </span>
                    </div>,
                  ],
            ]}
            {response.response_type === "AUDIO" && [
              <div
                key={`row2-row1-col-d8${index}`}
                className="audio-response-choices"
              >
                {isAnswered
                  ? [
                      response.is_correct === 1 ? (
                        <img
                          key={`row2-row1-col-d9${index}`}
                          alt=""
                          src={button_replay_correct}
                          onClick={() =>
                            onClickPlayAudio(
                              lessonAssetPath +
                                `audio/${response.response_content}`
                            )
                          }
                        />
                      ) : (
                        <img
                          style={{ opacity: 0.5 }}
                          key={`row2-row1-col-d9${index}`}
                          alt=""
                          src={button_replay}
                          onClick={() =>
                            onClickPlayAudio(
                              lessonAssetPath +
                                `audio/${response.response_content}`
                            )
                          }
                        />
                      ),
                    ]
                  : [
                      showingConfetti && response.is_correct === 1
                        ? [
                            <div
                              className="audio-correct-button-container"
                              id={`audio-response-${index}`}
                            ></div>,
                          ]
                        : [
                            <div
                              className="audio-button-container"
                              id={`audio-response-${index}`}
                            >
                              <div
                                className="audio-play-box"
                                onClick={() =>
                                  onClickPlayAudio(
                                    lessonAssetPath +
                                      `audio/${response.response_content}`,
                                    `audio-response-${index}`
                                  )
                                }
                                onMouseDown={(e) =>
                                  (e.target.parentNode.src = button_replay)
                                }
                              ></div>
                              <div
                                className="audio-select-box"
                                onClick={() => onAnswerClick(response.id)}
                              ></div>
                            </div>,
                          ],
                    ]}
              </div>,
            ]}
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TextTemplate;
