let localization = {
    "Practice": "Practica",
    "Next": "Siguiente",
    "Skip": "Omitir",
    "Back": "AtrRegresarás",
    "Level": "Nivel",
    "Unit": "Unidad",
    "Lesson": "Lección",
    "Levels": "Niveles",
    "Units": "Unidades",
    "Lessons": "Lecciones",
    "Total": "Total",
    "Seconds": "Segundos",
    "Sec": "Seg.",
    "Min": "Min.",
    "Minutes": "Minuto",
    "Minute": "Minute",
    "Hour": "Hora",
    "Hours": "Horas",
    "Day": "Día",
    "Days": "Dias",
}

export default localization;