import React, { useState, useEffect, useRef, Suspense } from "react";
import { Modal, Button } from "react-bootstrap";
import "./style.css";
import { getUserDetailsForLeaderBoard } from "../../utils/ApiHelpers";
import { getUserId } from "../../utils/DbHelpers";
import starFill from "../../assets/Simon-game-leader-board/8665872-512@2x.png";
import starOutline from "../../assets/Simon-game-leader-board/8530625-512@2x.png";
import avatar1 from "../../assets/Simon-game-leader-board/4043238_avatar_boy_kid_person_icon@2x.png";
import avatar2 from "../../assets/Simon-game-leader-board/4043279_afro_avatar_male_man_icon(1)@2x.png";
import avatar3 from "../../assets/Simon-game-leader-board/628298_anonym_avatar_default_head_person_icon@2x.png";
import heartFill from "../../assets/Simon-game-leader-board/299063-512@2x.png";
import backBtn from "../../assets/Simon-game-leader-board/Group2993@2x.png";
import retryBtn from "../../assets/Simon-game-leader-board/326591-512@2x.png";
import closeBtn from "../../assets/Simon-game-leader-board/Group2994@2x.png";

function LeaderBoard({
  handleLBClose,
  leaderBoardShow,
  restartGame,
  quitGame,
  currentStatus,
  topPlayers,
  currentUser,
  score,
}) {
  const [topPlayerOneName, setTopPlayerOneName] = useState(null);
  const [topPlayerTwoName, setTopPlayerTwoName] = useState(null);
  const [topPlayerThreeName, setTopPlayerThreeName] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);
  const [livesArray, setLivesArray] = useState([]);
  const [boardPlayed, setBoardPlayed] = useState(0);
  const [currentScore, setCurrentScore] = useState(0);

  useEffect(() => {
    const getUsersDetails = async () => {
      let params = "";
      for (let index = 0; index < topPlayers.length; index++) {
        if (index === 0) {
          params += "id=" + topPlayers[index].user_id;
        } else {
          params += "&id=" + topPlayers[index].user_id;
        }
      }
      let user_id;
      if (currentUser.length === 0) {
        // set state if user is first time playing the game
        setBoardPlayed(1);
        setCurrentScore(score);
        user_id = await getUserId();
      } else {
        // set state when user has some previous record in db
        setLivesArray([]);
        if (currentUser[0].live_count > 0) {
          if (score < currentUser[0].score) {
            for (let index = 0; index < (currentUser[0].live_count - 1); index++) {
              setLivesArray((livesArray) => [...livesArray, index + 1]);
            }
          }else{
            for (let index = 0; index < currentUser[0].live_count; index++) {
              setLivesArray((livesArray) => [...livesArray, index + 1]);
            }
          }
        }
        if (score > currentUser[0].score) {
          setCurrentScore(score);
        } else {
          setCurrentScore(currentUser[0].score);
        }
        console.log('game board....',currentUser[0].boards_played);
        setBoardPlayed(currentUser[0].boards_played + 1);
        user_id = currentUser[0].user_id;
      }

      let data = await getUserDetailsForLeaderBoard(params + "&id=" + user_id);
      data.filter((item, index) => {
        if (item.pk === topPlayers[0].user_id) {
          setTopPlayerOneName(item.first_name + " " + item.last_name);
        }
        if (item.pk === topPlayers[1].user_id) {
          setTopPlayerTwoName(item.first_name + " " + item.last_name);
        }
        if (item.pk === topPlayers[2].user_id) {
          setTopPlayerThreeName(item.first_name + " " + item.last_name);
        }
        if (item.pk === user_id) {
          setCurrentUserName(item.first_name + " " + item.last_name);
        }
      });
    };

    if (currentStatus) {
      getUsersDetails();
    }
  }, [
    currentStatus,
    topPlayerOneName,
    topPlayerTwoName,
    topPlayerThreeName,
    boardPlayed,
    currentScore,
  ]);

  return (
    <Modal
      show={leaderBoardShow}
      onHide={handleLBClose}
      backdrop="static"
      keyboard={false}
      centered
      dialogClassName="border-radius-2"
    >
      <Modal.Body className="my-modal-body">
        <div className="card-container">
          <div className="top-btn-section">
            <div className="close-btn" onClick={restartGame}>
              <img src={closeBtn} width="50%" />
            </div>
          </div>
          <div className="star-section">
            <img src={starFill} width="20%" className="left-star" />
            <img src={starOutline} width="22%" />
            <img src={starOutline} width="20%" className="right-star" />
          </div>
          <div className="score-section">
            <h3 className="name">
              {currentUserName ? currentUserName : "No Name"}
            </h3>
            <h1 className="score">{currentScore}</h1>
            <h4 className="board-played blink">
              GAME BOARD PLAYED: {boardPlayed}
            </h4>
          </div>
          <div className="top-three-section">
            <div className="player">
              <img src={avatar1} width="60%" />
              <p className="avatar-name">
                {topPlayerOneName ? topPlayerOneName : "No Name"}
              </p>
              <p className="avatar-score">
                Score: {topPlayers && topPlayers[0].score}
              </p>
            </div>
            <div className="player">
              <img src={avatar2} width="60%" />
              <p className="avatar-name">
                {topPlayerTwoName ? topPlayerTwoName : "No Name"}
              </p>
              <p className="avatar-score">
                Score: {topPlayers && topPlayers[1].score}
              </p>
            </div>
            <div className="player">
              <img src={avatar3} width="60%" />
              <p className="avatar-name">
                {topPlayerThreeName ? topPlayerThreeName : "No Name"}
              </p>
              <p className="avatar-score">
                Score: {topPlayers && topPlayers[2].score}
              </p>
            </div>
          </div>
          <div className="lives-section">
            {livesArray.map((item, index) => (
              <img key={index} src={heartFill} width="8%" />
            ))}
          </div>
          <div className="btn-section">
            <div className="back-btn arrow-bounce" onClick={quitGame}>
              <img src={backBtn} width="50%" />
            </div>
            <div className="retry-btn zoomIn" onClick={restartGame}>
              <img src={retryBtn} width="50%" />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LeaderBoard;
