const os = require("os");

// TODO: Make this configurable using .env. For now will use alternate lines.
// DEV
var API_BASE_URL_PARAM, ASSETS_BASE_PATH_PARM, LMS_URL_PARM;

if (os.hostname().indexOf("ea-dev.elltechnologies") > -1) {
  API_BASE_URL_PARAM = "https://api-ea-dev.elltechnologies.com";
  ASSETS_BASE_PATH_PARM = "https://cdn-ea-dev.elltechnologies.com";
  LMS_URL_PARM = "https://lms-dev.elltechnologies.com";
  console.log("Dev Env.");
}
if (os.hostname().indexOf("ea-demo.elltechnologies") > -1) {
  API_BASE_URL_PARAM = "https://api-ea-demo.elltechnologies.com";
  ASSETS_BASE_PATH_PARM = "https://cdn-ea-demo.elltechnologies.com";
  LMS_URL_PARM = "https://learn.elltechnologies.com";
  console.log("DEMO Env.");
}
if (os.hostname().indexOf("ea.elltechnologies") > -1) {
  API_BASE_URL_PARAM = "https://api-ea.elltechnologies.com";
  ASSETS_BASE_PATH_PARM = "https://cdn-ea.elltechnologies.com";
  LMS_URL_PARM = "https://learn.elltechnologies.com";
  console.log("Prod Env.");
}

//If you want to host locally on other domain for development
if (os.hostname().indexOf("local") > -1) {
  API_BASE_URL_PARAM = "http://localhost:3001";
  ASSETS_BASE_PATH_PARM = "https://cdn-ea-dev.elltechnologies.com";
  LMS_URL_PARM = "https://lms-dev.elltechnologies.com";
}

//BASE PATHS
export const API_BASE_URL = API_BASE_URL_PARAM;
export const ACCESS_TOKEN_NAME = "";
export const ASSETS_BASE_PATH = ASSETS_BASE_PATH_PARM;
export const LMS_URL = LMS_URL_PARM;
export const API_BASE_URL_PATH = API_BASE_URL_PARAM;
export const NET_CONNECTIVITY_TEST_URL = API_BASE_URL_PARAM + "/health";

//Other Data APIS
export const POST_ANSWER_RESPONSE = API_BASE_URL_PARAM + "/answer";
export const INITIAL_DATA_URL = API_BASE_URL_PARAM + "/initial_data";
export const LEVELS_DATA_URL = API_BASE_URL_PARAM + "/levels";
export const UNITS_DATA_URL = API_BASE_URL_PARAM + "/units";
export const LESSON_DATA_URL = API_BASE_URL_PARAM + "/lessons";
