import axios from "axios";
import {
  API_BASE_URL,
  POST_ANSWER_RESPONSE,
  INITIAL_DATA_URL,
  LESSON_DATA_URL,
} from "../constants/constants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

// Save Live Counter
export const saveLiveCounterInDb = async (user_id) => {
  var headers = {
    "Content-Type": "application/json",
  };

  axios
    .get(API_BASE_URL + `/store_live_counter/${user_id}`, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Get user details
export const getUserDetailsForLeaderBoard = async (params) => {
  var headers = {
    Authorization: "Token 6938013c4b4ea0f8b8f525850a91153d63d1ab9c",
    "Content-Type": "application/json",
  };

  try {
    const res = await fetch(
      `https://serenity.elltechnologies.com/users/?${params}`,
      {
        headers: headers,
      }
    );
    const data = await res.json();
    if (data.status === "success") return data.payload;
    else return false;
  } catch (e) {
    console.log("Failed fetching users data"); // handle error
    return false;
  }
};

// Get data for leader board
export const getAllUsersScore = async () => {
  try {
    const res = await fetch(`${API_BASE_URL}/get_users_score`);
    const data = await res.json();
    if (data.success === "ok") return data;
    else return false;
  } catch (e) {
    console.log("Failed fetching users score"); // handle error
    return false;
  }
};

// Store user's game response back to db
export const storeGameResponse = async (data) => {
  var headers = {
    "Content-Type": "application/json",
  };

  axios
    .post(API_BASE_URL + "/game_progress/", data, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Fetch game data
export const fetchGameData = async (user_id) => {
  try {
    const res = await fetch(`${API_BASE_URL}/fetch_game/${user_id}`);
    const data = await res.json();
    if (data.success === "ok") return data;
    else return false;
  } catch (e) {
    console.log("Failed fetching game data", e.message); // handle error
    return false;
  }
};

// Add new game board
export const addNewGame = async (data) => {
  var headers = {
    "Content-Type": "multipart/form-data",
  };

  axios
    .post(API_BASE_URL + "/add_new_board/", data, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
      MySwal.fire("New Game Board created successfully!");
    })
    .catch((err) => {
      console.log(err);
    });
};

// Update questions
export const updateQuestions = async (data) => {
  // console.log(data.questionType);
  var headers = {
    "Content-Type": "multipart/form-data",
  };

  axios
    .post(API_BASE_URL + "/update_question/", data, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Add Levels
export const addLevels = async (data) => {
  var headers = {
    "Content-Type": "application/json",
  };

  axios
    .post("http://localhost:3001/add_level/", data, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Edit Levels
export const editLevels = async (data) => {
  var headers = {
    "Content-Type": "application/json",
  };

  axios
    .post("http://localhost:3001/edit_level/", data, {
      headers: headers,
    })
    .then((res) => {
      MySwal.fire("Level updated succefully.");
    })
    .catch((err) => {
      console.log(err);
    });
};

// Delete Levels
export const deleteLevel = async (level_id) => {
  const data = {
    level_id: level_id,
  };

  var headers = {
    "Content-Type": "application/json",
  };

  MySwal.fire({
    title: "Are you sure?",
    text: "You want to deletd this level?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .post("http://localhost:3001/delete_level/", data, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.success === "no") {
            MySwal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.message,
            });
          } else if (res.data.success === "ok") {
            MySwal.fire("Deleted!", "Level has been deleted.", "success");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};

// Add Units
export const addUnits = async (data) => {
  var headers = {
    "Content-Type": "application/json",
  };

  axios
    .post("http://localhost:3001/add_units/", data, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Edit Units
export const editUnits = async (data) => {
  var headers = {
    "Content-Type": "application/json",
  };

  axios
    .post("http://localhost:3001/edit_units/", data, {
      headers: headers,
    })
    .then((res) => {
      MySwal.fire("Unit updated succefully.");
    })
    .catch((err) => {
      console.log(err);
    });
};

// Delete Units
export const deleteUnit = async (unit_id) => {
  const data = {
    unit_id: unit_id,
  };

  var headers = {
    "Content-Type": "application/json",
  };

  MySwal.fire({
    title: "Are you sure?",
    text: "You want to delete this unit?",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .post("http://localhost:3001/delete_unit/", data, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.success === "no") {
            MySwal.fire({
              icon: "error",
              title: "Oops...",
              text: res.data.message,
            });
          } else if (res.data.success === "ok") {
            MySwal.fire("Deleted!", "Unit has been deleted.", "success");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  });
};

// Add Lessons
export const addLessons = async (data) => {
  const headers = {
    "Content-Type": "application/json",
  };

  axios
    .post("http://localhost:3001/add_lessons/", data, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Add Questions
export const addQuestions = async (data) => {
  console.log("This is data comming for add questions...", data);
  const headers = {
    "Content-Type": "multipart/form-data",
  };

  axios
    .post("http://localhost:3001/add_question/", data, {
      headers: headers,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((err) => {
      console.log(err);
    });
};

// Fetch Levels
export const fetchInitialData = async (user_id) => {
  try {
    const res = await fetch(`${INITIAL_DATA_URL}/${user_id}`);
    const data = await res.json();
    console.log("this initail data....", data);
    if (data.success === "ok") return data;
    else return false;
  } catch (e) {
    console.log("Failed fetching levels", e.message); // handle error
    return false;
  }
};

// Fetch Levels
export const fetchUnitLessons = async (unit_id, lesson_id, user_id) => {
  try {
    const res = await fetch(
      `${LESSON_DATA_URL}/${user_id}/${unit_id}/${lesson_id}`
    );
    const data = await res.json();
    if (data.success === "ok") return data;
    else return false;
  } catch (e) {
    console.log("Failed fetching levels"); // handle error
    return false;
  }
};

// Update Question
export const updateServerQuestionScore = async (question_data, user_id) => {
  try {
    question_data.user_id = user_id;
    return await axios({
      method: "post",
      url: POST_ANSWER_RESPONSE,
      data: question_data,
    })
      .then((response) => {
        return true;
      })
      .catch((response) => {
        return false;
      });
  } catch (e) {
    console.log("Failed udpating server for answer"); // handle error
    return false;
  }
};

export const getUserLessonProgress = async (user_id, lesson_id) => {
  try {
    const res = await fetch(
      `${API_BASE_URL}/users/${user_id}/progress/${lesson_id}`
    );
    const data = await res.json();
    if (data.success === "ok") return data;
    else return false;
  } catch (e) {
    console.log("Failed fetching levels"); // handle error
    return false;
  }
};

export const getLessonS3Data = async (path) => {
  try {
    const res = await fetch(path);

    if (res.status === 403) return false;

    const data = await res.json();

    if (data) return data;
    else return false;
  } catch (e) {
    console.log(e); // handle error
    return false;
  }
};

export const getSubtitlesS3Data = async (path) => {
  try {
    const res = await fetch(path);

    if (res.status === 403) return false;

    const data = await res.text();

    if (data) return data;
    else return false;
  } catch (e) {
    console.log(e); // handle error
    return false;
  }
};

// ***********************************************
// ***********************************************
//
//    FOLLOWING APIS ARE NOT IN USE NOW. But can be used in future.
//
// ***********************************************
// ***********************************************

// Fetch Levels
export const fetchLevels = async () => {
  try {
    const res = await fetch(`${API_BASE_URL}/get_levels`);
    const data = await res.json();
    return data;
  } catch (e) {
    console.log("Failed fetching levels"); // handle error
  }
};

// Fetch Units
export const fetchUnits = async (level_id) => {
  try {
    const res = await fetch(`${API_BASE_URL}/get_units/$(level_id)`);
    const data = await res.json();
    return data;
  } catch (e) {
    console.log("Failed fetching units"); // handle error
  }
};

// Fetch Lessons
export const fetchLessons = async (unit_id) => {
  try {
    const res = await fetch(`${API_BASE_URL}/get_lessons/${unit_id}`);
    const data = await res.json();
    return data;
  } catch (e) {
    console.log("Failed fetching units"); // handle error
  }
};

// Fetch Lessons
export const fetchQuestionsAndResponses = async (lesson_id) => {
  try {
    const res = await fetch(
      `${API_BASE_URL}/get_question_and_responses/${lesson_id}`
    );
    const data = await res.json();
    return data;
  } catch (e) {
    console.log("Failed fetching units"); // handle error
  }
};
