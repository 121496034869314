let localization = {
    "Practice": "练习",
    "Next": "下一个",
    "Skip": "跳过",
    "Back": "返回",
    "Level": "级别",
    "Unit": "单元",
    "Lesson": "课",
    "Levels": "级别",
    "Units": "单元",
    "Lessons": "课",
    "Total": "总计",
    "Seconds": "秒",
    "Sec": "秒",
    "Min": "分",
    "Minutes": "分",
    "Minute": "分",
    "Hour": "小时",
    "Hours": "小时",
    "Day": "天",
    "Days": "天",
}

export default localization;