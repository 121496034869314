import React, { useState, useRef, useEffect, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import styled from "styled-components";
import { MdClose } from "react-icons/md";
import { addNewGame } from "../../utils/ApiHelpers";

const Background = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 30;
  margin: auto;
`;
const ModalWrapper = styled.div`
  width: 800px;
  max-height: 700px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  grid-template-columns: 1fr;
  position: relative;
  z-index: 30;
  border-radius: 10px;
  overflow: scroll;
  padding-bottom: 25px;
`;

const CloseModalButton = styled(MdClose)`
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 30;
`;

const AddNewGameBoard = ({ showModal, setShowModal }) => {
  const modalRef = useRef();

  const [btnDisable, setBtnDisable] = useState(true);

  const [imageOneContent, setImageOneContent] = useState(null);
  const [imageTwoContent, setImageTwoContent] = useState(null);
  const [imageThreeContent, setImageThreeContent] = useState(null);
  const [imageFourContent, setImageFourContent] = useState(null);
  const [audioContent, setAudioContent] = useState(null);

  const [imageOneName, setImageOneName] = useState(null);
  const [imageTwoName, setImageTwoName] = useState(null);
  const [imageThreeName, setImageThreeName] = useState(null);
  const [imageFourName, setImageFourName] = useState(null);
  const [audioName, setAudioName] = useState(null);

  const formInputValidation = (e) => {
    if (e.target.name === "image1_content") {
      setImageOneName(e.target.files[0].name);
      imageHandler(e, e.target.name);
      setBtnDisable(
        e.target.value.length &&
          imageTwoContent &&
          imageThreeContent &&
          imageFourContent &&
          audioContent
          ? false
          : true
      );
    } else if (e.target.name === "image2_content") {
      setImageTwoName(e.target.files[0].name);
      imageHandler(e, e.target.name);
      setBtnDisable(
        e.target.value.length &&
          imageOneContent &&
          imageThreeContent &&
          imageFourContent &&
          audioContent
          ? false
          : true
      );
    } else if (e.target.name === "image3_content") {
      setImageThreeName(e.target.files[0].name);
      imageHandler(e, e.target.name);
      setBtnDisable(
        e.target.value.length &&
          imageOneContent &&
          imageTwoContent &&
          imageFourContent &&
          audioContent
          ? false
          : true
      );
    } else if (e.target.name === "image4_content") {
      setImageFourName(e.target.files[0].name);
      imageHandler(e, e.target.name);
      setBtnDisable(
        e.target.value.length &&
          imageOneContent &&
          imageTwoContent &&
          imageThreeContent &&
          audioContent
          ? false
          : true
      );
    } else if (e.target.name === "audio") {
      setAudioName(e.target.files[0].name);
      imageHandler(e, e.target.name);
      setBtnDisable(
        e.target.value.length &&
          imageOneContent &&
          imageTwoContent &&
          imageThreeContent &&
          imageFourContent
          ? false
          : true
      );
    }
  };

  const imageHandler = (e, file_name) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        if (file_name === "image1_content") {
          setImageOneContent(reader.result);
        } else if (file_name === "image2_content") {
          setImageTwoContent(reader.result);
        } else if (file_name === "image3_content") {
          setImageThreeContent(reader.result);
        } else if (file_name === "image4_content") {
          setImageFourContent(reader.result);
        } else if (file_name === "audio") {
          setAudioContent(reader.result);
        }
      }
    };
    // setbuttonDisable(false)
    reader.readAsDataURL(e.target.files[0]);
  };

  const formSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();

    data.append("img1_content", imageOneContent);
    data.append("img1_name", imageOneName);
    data.append("img2_content", imageTwoContent);
    data.append("img2_name", imageTwoName);
    data.append("img3_content", imageThreeContent);
    data.append("img3_name", imageThreeName);
    data.append("img4_content", imageFourContent);
    data.append("img4_name", imageFourName);
    data.append("audio", audioContent);
    data.append("audio_name", audioName);

    addNewGame(data);

    buttonClose();
  };

  const setValuesToNull = () => {
    setImageOneContent(null);
    setImageTwoContent(null);
    setImageThreeContent(null);
    setImageFourContent(null);
    setAudioContent(null);
    setBtnDisable(true);
    setImageOneName(null);
    setImageTwoName(null);
    setImageThreeName(null);
    setImageFourName(null);
    setAudioName(null);
  };

  const animation = useSpring({
    config: {
      duration: 250,
    },
    opacity: showModal ? 1 : 0,
    transform: showModal ? `translateY(0%)` : `translateY(-100%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
      setValuesToNull();
    }
  };

  const buttonClose = () => {
    setShowModal(false);
    setValuesToNull();
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
        setValuesToNull();
      }
    },
    [setShowModal, showModal]
  );

  useEffect(async () => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);

  return (
    <>
      {showModal ? (
        <Background ref={modalRef} onClick={closeModal}>
          <animated.div style={animation}>
            <ModalWrapper showModal={showModal}>
              <div className="row">
                <div className="col-12" style={{ paddingLeft: 25 }}>
                  <h1>Add New Game Board</h1>
                </div>
              </div>
              <form onSubmit={formSubmit} className="form">
                <div className="row">
                  <div className="col-6 form-group mt-3">
                    <label>Image: 1</label>
                    <input
                      type="file"
                      name="image1_content"
                      placeholder="Content 1"
                      className="form-control"
                      onChange={(e) => formInputValidation(e)}
                    />
                  </div>
                  <div className="col-6 form-group mt-3">
                    <label>Image: 2</label>
                    <input
                      type="file"
                      name="image2_content"
                      placeholder="Content"
                      className="form-control"
                      onChange={(e) => formInputValidation(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 form-group mt-3">
                    <label>Image: 3</label>
                    <input
                      type="file"
                      name="image3_content"
                      placeholder="Content 3"
                      className="form-control"
                      onChange={(e) => formInputValidation(e)}
                    />
                  </div>
                  <div className="col-6 form-group mt-3">
                    <label>Image: 4</label>
                    <input
                      type="file"
                      name="image4_content"
                      placeholder="Content 4"
                      className="form-control"
                      onChange={(e) => formInputValidation(e)}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 form-group mt-3">
                    <label>Audio:</label>
                    <input
                      type="file"
                      name="audio"
                      placeholder="Content"
                      className="form-control"
                      onChange={(e) => formInputValidation(e)}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 offset-9 form-group mt-4">
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="Basic example"
                    >
                      <button
                        type="button"
                        disabled={btnDisable}
                        className="btn btn-danger"
                        onClick={() => buttonClose()}
                      >
                        Cancel
                      </button>
                      <input
                        type="submit"
                        disabled={btnDisable}
                        className="btn btn-primary"
                        value="Submit"
                      />
                    </div>
                  </div>
                </div>
              </form>

              <CloseModalButton
                aria-label="Close modal"
                onClick={() => buttonClose()}
              />
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};

export default AddNewGameBoard;
