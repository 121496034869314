import React, { useState, useEffect } from "react";
import "./style.css";
import { useHistory, useLocation } from "react-router-dom";
import { MdAdd } from "react-icons/md";
import AddNewGameBoard from "./AddNewGameBoard";
import { getUserId, checkIsAdmin } from "../../utils/DbHelpers";
import { storeGameResponse } from "../../utils/ApiHelpers";
import { ASSETS_BASE_PATH } from "../../constants/constants";
import LeaderBoard from "../LeaderBoard/LeaderBoard";
import { getAllUsersScore } from "../../utils/ApiHelpers";

const SimonGame = () => {
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [gameData, setGameData] = useState();
  const [score, setScore] = useState(0);
  const [gameResultStatus, setGameResultStatus] = useState(false);
  const [userId, setUserId] = useState();
  const [userScore, setUserScore] = useState(0);
  const [sequenceArray, setSequenceArray] = useState([]);
  const [clickCounterArray, setClickCounterArray] = useState([]);
  const [counter, setCounter] = useState(0);
  const [animation, setAnimation] = useState(true);
  const [reStart, setReStart] = useState();
  const [isAdmin, setIsAdmin] = useState(false);

  // state for animations
  const [firstAnimation, setFirstAnimation] = useState(false);
  const [secondAnimation, setSecondAnimation] = useState(false);
  const [thirdAnimation, setThirdAnimation] = useState(false);
  const [fourthAnimation, setFourthAnimation] = useState(false);
  // state for leader board
  const [leaderBoardShow, setLeaderBoardShow] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);
  const [currentUser, setCurrentUser] = useState([]);
  const [topPlayers, setTopPlayers] = useState();

  const handleLBClose = () => {
    setCurrentStatus(false);
    setLeaderBoardShow(false);
  };
  const handleLBShow = () => {
    setCurrentStatus(true);
    setLeaderBoardShow(true);
  };

  const openModel = () => {
    setShowModal((prev) => !prev);
  };

  const processResponse = async (val) => {
    setFirstAnimation(false);
    setSecondAnimation(false);
    setThirdAnimation(false);
    setFourthAnimation(false);

    let arry1 = [];

    if (val === sequenceArray[counter]) {
      if (clickCounterArray.length === 0) {
        arry1 = arry1.concat(val);
      }

      setClickCounterArray((clickCounterArray) => [...clickCounterArray, val]);
      arry1 = [].concat(...clickCounterArray);
      arry1 = arry1.concat(val);

      if (arry1.length === sequenceArray.length) {
        setClickCounterArray([]);
        setCounter(0);

        setScore(score + 1);
      } else {
        setCounter(counter + 1);
      }
    } else {
      let data = {
        user_id: userId,
        score: score,
      };
      await storeGameResponse(data);

      let users_data = await getAllUsersScore();
      console.log("getted user data.....", users_data);
      let user_id = await getUserId();
      let top = users_data.users_score
        .sort((a, b) => b.score - a.score)
        .slice(0, 3);
      let current = users_data.users_score.filter((item) => {
        if (item.user_id === user_id) {
          return true;
        }
      });

      setTopPlayers(top);
      setCurrentUser(current);

      handleLBShow();
      // setGameResultStatus(true)
    }
  };

  const playAudio = (audio_file) => {
    let audio = new Audio(audio_file);
    audio.play();
  };

  const restartGame = () => {
    setCounter(0);
    setClickCounterArray([]);
    setSequenceArray([]);
    setScore(0);
    setReStart((prev) => !prev);
    handleLBClose();
    // setGameResultStatus(false)
  };

  const quitGame = () => {
    localStorage.clear();
    history.push(
      `/levels/${location.state.level_id}/units/${location.state.unit_id}/lessons/${location.state.lesson_id}/questions/${location.state.question_order_no}`
    );
  };

  useEffect(() => {
    const getUser = async () => {
      const user_id = await getUserId();
      const admin = await checkIsAdmin();
      setUserId(user_id);

      if (admin === true) {
        setIsAdmin(true);
      }
    };

    const getGameDataFromLocalStorage = () => {
      // Get Game data from local storage
      let game_data = JSON.parse(localStorage.getItem("gameData"));
      {
        localStorage.getItem("userScore") &&
          setUserScore(localStorage.getItem("userScore"));
      }

      // Getting random game record to display on the board
      let random_game = game_data[Math.floor(Math.random() * game_data.length)];
      setGameData(random_game);
    };

    let timeOutID1 = null;
    let timeOutID2 = null;

    if (!animation) {
      // Set an array to tack a random value from and set a card animation base on that value
      let randomArray = [1, 2, 3, 4];
      let randomAnimationValue = null;

      randomAnimationValue =
        randomArray[Math.floor(Math.random() * randomArray.length)];

      setSequenceArray((sequenceArray) => [
        ...sequenceArray,
        randomAnimationValue,
      ]);

      let arr1d = [].concat(...sequenceArray);
      arr1d = arr1d.concat(randomAnimationValue);

      for (let i = 0; i < arr1d.length; i++) {
        timeOutID1 = window.setTimeout(() => {
          if (arr1d[i] === 1) {
            setFirstAnimation(true);
            setSecondAnimation(false);
            setThirdAnimation(false);
            setFourthAnimation(false);
            playAudio(ASSETS_BASE_PATH + `/ea-game-data/${gameData.audio}`);
          }
          if (arr1d[i] === 2) {
            setSecondAnimation(true);
            setFirstAnimation(false);
            setThirdAnimation(false);
            setFourthAnimation(false);
            playAudio(ASSETS_BASE_PATH + `/ea-game-data/${gameData.audio}`);
          }
          if (arr1d[i] === 3) {
            setThirdAnimation(true);
            setFirstAnimation(false);
            setSecondAnimation(false);
            setFourthAnimation(false);
            playAudio(ASSETS_BASE_PATH + `/ea-game-data/${gameData.audio}`);
          }
          if (arr1d[i] === 4) {
            setFourthAnimation(true);
            setFirstAnimation(false);
            setSecondAnimation(false);
            setThirdAnimation(false);
            playAudio(ASSETS_BASE_PATH + `/ea-game-data/${gameData.audio}`);
          }

          // Why this timeout?
          // Because sometime we have same numbers occuring consecutively in the array like [2,2,2,2]
          // In that case we have only one animation for that card
          // so we need to clear the animation for the card after each trun
          // This timeout will do that
          timeOutID2 = window.setTimeout(() => {
            setFourthAnimation(false);
            setFirstAnimation(false);
            setSecondAnimation(false);
            setThirdAnimation(false);
          }, 400);
        }, 600 * i);
      }
    }

    let timeOutID3 = null;

    // wait for the first loading animation to finish
    if (animation) {
      timeOutID3 = window.setTimeout(() => {
        setAnimation(false);
      }, 2500);
    }
    
    if (!gameData) {
      getGameDataFromLocalStorage();
    }
    getUser();
    return () => window.clearTimeout(timeOutID1, timeOutID2, timeOutID3);
  }, [userScore, score, reStart, animation]);

  return (
    <div className="container-fluid si-body">
      <AddNewGameBoard showModal={showModal} setShowModal={setShowModal} />
      <LeaderBoard
        handleLBClose={handleLBClose}
        leaderBoardShow={leaderBoardShow}
        restartGame={restartGame}
        quitGame={() => quitGame()}
        currentStatus={currentStatus}
        topPlayers={topPlayers}
        currentUser={currentUser}
        score={score}
      />

      <div className="score-header">
        {/* <h1>HIGHT SCORE: {userScore}</h1> */}
        {isAdmin && (
          <button className="btn btn-link" onClick={openModel}>
            <MdAdd />
            Add New
          </button>
        )}
      </div>

      <div className={`game-container ${isAdmin ? '' : 'mt-5'}`}>
        <div className="score-counter">
          <h1 className="remove-user-select">{score}</h1>
        </div>

        <div className={`${animation ? "game-board-animation" : ""}`}>
          <div className="row game-board-row">
            <div className="col-6">
              <div
                className={`float-end left-top-triangle ${
                  firstAnimation ? "zoomIn" : ""
                } ${gameResultStatus ? "disable" : ""}`}
                onClick={() => processResponse(1)}
              >
                {gameData && (
                  <img
                    src={ASSETS_BASE_PATH + `/ea-game-data/${gameData.img1}`}
                    width="50%"
                    height="50%"
                    className="remove-user-select"
                  />
                )}
              </div>
            </div>

            <div className="col-6">
              <div
                className={`left-right-triangle ${
                  thirdAnimation ? "zoomIn" : ""
                } ${gameResultStatus ? "disable" : ""}`}
                onClick={() => processResponse(3)}
              >
                {gameData && (
                  <img
                    src={ASSETS_BASE_PATH + `/ea-game-data/${gameData.img2}`}
                    width="50%"
                    height="50%"
                    className="remove-user-select"
                  />
                )}
              </div>
            </div>

            <div className="col-6">
              <div
                className={`float-end bottom-left-triangle ${
                  fourthAnimation ? "zoomIn" : ""
                } ${gameResultStatus ? "disable" : ""}`}
                onClick={() => processResponse(4)}
              >
                {gameData && (
                  <img
                    src={ASSETS_BASE_PATH + `/ea-game-data/${gameData.img3}`}
                    width="50%"
                    height="50%"
                    className="remove-user-select"
                  />
                )}
              </div>
            </div>

            <div className="col-6">
              <div
                className={`bottom-right-triangle ${
                  secondAnimation ? "zoomIn" : ""
                } ${gameResultStatus ? "disable" : ""}`}
                onClick={() => processResponse(2)}
              >
                {gameData && (
                  <img
                    src={ASSETS_BASE_PATH + `/ea-game-data/${gameData.img4}`}
                    width="50%"
                    height="50%"
                    className="remove-user-select"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimonGame;
