import React from "react";

import answer_box from "../assets/answer_box.png";
import play_audio from "../assets/play_audio.png";
import button_correct from "../assets/button_normal.png";
import text_button from "../assets/text_button.png";
import audio_long from "../assets/long_play_button.png";

const answerBox = {
  backgroundImage: `url(${answer_box})`,
};

const textButton = {
  backgroundImage: `url(${button_correct})`,
  cursor: "default",
  width: 250,
};

const buttonStyle = {
  backgroundImage: `url(${text_button})`,
};

const PopUpPreview = ({
  question,
  responses,
  img1,
  img2,
  audio1,
  audio2,
  text1,
  text2,
  qesText,
}) => {
  const onClickPlayAudio = async (audio_file) => {
    let audio = new Audio(audio_file);
    audio.play();
  };

  return (
    <div className="container animate__animated animate__zoomIn">
      {(question.question_type === "IMAGE-AUDIO" ||
        question.question_type === "IMAGE-TEXT" ||
        question.question_type === "TEXT-AUDIO") && (
        <>
          <div className="row">
            {(question.question_type === "IMAGE-AUDIO" ||
              question.question_type === "IMAGE-TEXT") && (
              <div className="questions-text-button mb-3" style={buttonStyle}>
                {qesText}
              </div>
            )}
            {question.question_type === "TEXT-AUDIO" && (
              <div className="default-text-button " style={buttonStyle}>
                {qesText}
              </div>
            )}
          </div>
          <div className="row">
            {(question.question_type === "IMAGE-AUDIO" ||
              question.question_type === "IMAGE-TEXT") && (
              <>
                <div className="col-md-6">
                  <div
                    key="qb-div"
                    className="default-answer-box"
                    style={answerBox}
                  >
                    <img
                      key="qb-div-img"
                      alt=""
                      src={img1}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  {responses.map((res, index) => (
                    <>
                      {res.response_type === "AUDIO" && (
                        <div key={index} className="audio-response-choices">
                          <img
                            alt=""
                            src={play_audio}
                            onClick={() =>
                              onClickPlayAudio(index === 0 ? audio1 : audio2)
                            }
                            style={{ width: 80, height: 80 }}
                          />
                          <div
                            className="default-text-button"
                            style={textButton}
                          >
                            {res.response_content_label}
                          </div>
                        </div>
                      )}
                      {res.response_type === "TEXT" && (
                        <div
                          key={index}
                          className="default-text-button "
                          style={buttonStyle}
                        >
                          {index === 1 ? text1 : text2}
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </>
            )}
            {question.question_type === "TEXT-AUDIO" && (
              <>
                {responses.map((res, index) => (
                  <div key={index} className="col-md-6">
                    {res.response_type === "AUDIO" && (
                      <div className="audio-response-choices">
                        <img
                          alt=""
                          src={play_audio}
                          style={{ width: 80, height: 80 }}
                        />
                        <div className="default-text-button" style={textButton}>
                          {res.response_content_label}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}
          </div>
        </>
      )}
      {(question.question_type === "AUDIO-IMAGE" ||
        question.question_type === "TEXT-IMAGES" ||
        question.question_type === "AUDIO-TEXT" ||
        question.question_type === "TEXT-TEXT") && (
        <>
          <div className="row">
            {(question.question_type === "AUDIO-IMAGE" ||
              question.question_type === "AUDIO-TEXT") && (
              <div className="col-md-12 text-center">
                <img
                  className="mb-3"
                  alt=""
                  src={audio_long}
                  onClick={() => onClickPlayAudio(audio1)}
                  style={{ width: "450px", maxWidth: "80%", cursor: "pointer" }}
                />
              </div>
            )}
            {(question.question_type === "TEXT-IMAGES" ||
              question.question_type === "TEXT-TEXT") && (
              <div className="default-text-button " style={buttonStyle}>
                {qesText}
              </div>
            )}
          </div>
          <div className="row">
            {responses.map((res, index) => (
              <div key={index} className="col-md-6 text-center">
                {res.response_type === "IMAGE" && (
                  <div className="default-answer-box" style={answerBox}>
                    <img
                      key="qb-div-img"
                      alt=""
                      src={index + 1 === 1 ? img1 : img2}
                      style={{ height: "200px" }}
                    />
                  </div>
                )}
                {res.response_type === "TEXT" && (
                  <div className="default-text-button " style={buttonStyle}>
                    {index === 1 ? text1 : text2}
                  </div>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default PopUpPreview;
