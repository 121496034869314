import React from "react";

import answer_box from "../assets/answer_box.png";
import play_audio from "../assets/play_audio.png";
import button_correct from "../assets/button_normal.png";
import text_button from "../assets/text_button.png";
import audio_long from "../assets/long_play_button.png";

const answerBox = {
  backgroundImage: `url(${answer_box})`,
};

const textButton = {
  backgroundImage: `url(${button_correct})`,
  cursor: "default",
  width: 250,
};

const buttonStyle = {
  backgroundImage: `url(${text_button})`,
};

const AddQuestionPopUpPreview = ({
  questionType,
  responseType,
  responses,
  img1,
  img2,
  audio1,
  audio2,
  text1,
  text2,
  qesText,
  audioText1,
  audioText2,
}) => {
  const onClickPlayAudio = async (audio_file) => {
    let audio = new Audio(audio_file);
    audio.play();
  };

  return (
    <div className="container animate__animated animate__zoomIn">
      {(questionType === "IMAGE-AUDIO" ||
        questionType === "IMAGE-TEXT" ||
        questionType === "TEXT-AUDIO") && (
        <>
          <div className="row">
            {(questionType === "IMAGE-AUDIO" ||
              questionType === "IMAGE-TEXT") && (
              <div className="questions-text-button mb-3" style={buttonStyle}>
                {qesText}
              </div>
            )}
            {questionType === "TEXT-AUDIO" && (
              <div className="default-text-button " style={buttonStyle}>
                {qesText}
              </div>
            )}
          </div>
          <div className="row">
            {questionType === "IMAGE-TEXT" && (
              <>
                <div className="col-md-6">
                  <div
                    key="qb-div"
                    className="default-answer-box"
                    style={answerBox}
                  >
                    <img
                      key="qb-div-img"
                      alt=""
                      src={img1}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
                {responseType === "TEXT" && (
                  <>
                    <div className="row">
                      <div className="col-md-6">
                        <div
                          className="default-text-button "
                          style={buttonStyle}
                        >
                          {text1}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div
                          className="default-text-button "
                          style={buttonStyle}
                        >
                          {text2}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {questionType === "IMAGE-AUDIO" && (
              <>
                <div className="col-md-6">
                  <div
                    key="qb-div"
                    className="default-answer-box"
                    style={answerBox}
                  >
                    <img
                      key="qb-div-img"
                      alt=""
                      src={img1}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
                {responseType === "AUDIO" && (
                  <>
                    <div className="col-md-6">
                      <div className="audio-response-choices">
                        <img
                          alt=""
                          src={play_audio}
                          onClick={() => onClickPlayAudio(audio1)}
                          style={{ width: 80, height: 80 }}
                        />
                        <div className="default-text-button" style={textButton}>
                          {audioText1}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="audio-response-choices">
                        <img
                          alt=""
                          src={play_audio}
                          onClick={() => onClickPlayAudio(audio2)}
                          style={{ width: 80, height: 80 }}
                        />
                        <div className="default-text-button" style={textButton}>
                          {audioText2}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {questionType === "TEXT-AUDIO" && (
              <>
                {responseType === "AUDIO" && (
                  <>
                    <div className="col-md-6">
                      <div className="audio-response-choices">
                        <img
                          alt=""
                          src={play_audio}
                          onClick={() => onClickPlayAudio(audio1)}
                          style={{ width: 80, height: 80 }}
                        />
                        <div className="default-text-button" style={textButton}>
                          {audioText1}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="audio-response-choices">
                        <img
                          alt=""
                          src={play_audio}
                          onClick={() => onClickPlayAudio(audio2)}
                          style={{ width: 80, height: 80 }}
                        />
                        <div className="default-text-button" style={textButton}>
                          {audioText2}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
      {questionType === "AUDIO-IMAGE-TEXT" && (
        <>
          <div className="row">
            <div className="col-md-12 text-center">
              <img
                className="mb-3"
                alt=""
                src={audio_long}
                onClick={() => onClickPlayAudio(audio1)}
                style={{ width: "450px", maxWidth: "80%", cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 text-center">
              <div className="default-answer-box" style={answerBox}>
                <img
                  key="qb-div-img"
                  alt=""
                  src={img1}
                  style={{ height: "200px" }}
                />
              </div>
            </div>
            <div className="col-md-6 text-center">
              <div className="default-text-button " style={buttonStyle}>
                {text1}
              </div>
              <div className="default-text-button " style={buttonStyle}>
                {text1}
              </div>
            </div>
          </div>
        </>
      )}
      {(questionType === "AUDIO-IMAGE" ||
        questionType === "TEXT-IMAGES" ||
        questionType === "AUDIO-TEXT" ||
        questionType === "TEXT-TEXT") && (
        <>
          <div className="row">
            {(questionType === "AUDIO-IMAGE" ||
              questionType === "AUDIO-TEXT") && (
              <div className="col-md-12 text-center">
                <img
                  className="mb-3"
                  alt=""
                  src={audio_long}
                  onClick={() => onClickPlayAudio(audio1)}
                  style={{ width: "450px", maxWidth: "80%", cursor: "pointer" }}
                />
              </div>
            )}
            {(questionType === "TEXT-IMAGES" ||
              questionType === "TEXT-TEXT") && (
              <div className="default-text-button " style={buttonStyle}>
                {qesText}
              </div>
            )}
          </div>
          <div className="row">
            {responseType === "IMAGE" && (
              <>
                <div className="col-md-6 text-center">
                  <div className="default-answer-box" style={answerBox}>
                    <img
                      key="qb-div-img"
                      alt=""
                      src={img1}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <div className="default-answer-box" style={answerBox}>
                    <img
                      key="qb-div-img"
                      alt=""
                      src={img2}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
              </>
            )}

            {responseType === "IMAGES" && (
              <>
                <div className="col-md-6 text-center">
                  <div className="default-answer-box" style={answerBox}>
                    <img
                      key="qb-div-img"
                      alt=""
                      src={img1}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <div className="default-answer-box" style={answerBox}>
                    <img
                      key="qb-div-img"
                      alt=""
                      src={img2}
                      style={{ height: "200px" }}
                    />
                  </div>
                </div>
              </>
            )}

            {responseType === "TEXT" && (
              <>
                <div className="col-md-6 text-center">
                  <div className="default-text-button " style={buttonStyle}>
                    {text1}
                  </div>
                </div>
                <div className="col-md-6 text-center">
                  <div className="default-text-button " style={buttonStyle}>
                    {text2}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AddQuestionPopUpPreview;
